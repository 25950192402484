import { Box, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { APIException } from '../common/exception';
import { LoginForm, LoginFormProps } from '../components/LoginForm';
import { AuthContainer } from '../stores/Auth';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';

export const LoginPage = () => {
  const { t } = useTranslation();
  const { login } = AuthContainer.useContainer();
  const [error, setError] = useState<APIException>();

  const handleSubmit: LoginFormProps['onSubmit'] = async (auth) => {
    try {
      await login(auth);
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
      }
    }
  };

  return (
    <Box sx={{ pt: 4, maxWidth: '100%', width: 360, margin: '0 auto' }}>
      <Typography variant="h5">{t('loginPage.title')}</Typography>
      {error && error?.state?.general && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error.state.general}
        </Alert>
      )}
      <Paper sx={{ mt: 2, p: 2 }}>
        <LoginForm onSubmit={handleSubmit} error={error?.state?.form} />

        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <Typography color="primary" component={Link} to="/reset-pass">
            {t('loginPage.resetPass')}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};
