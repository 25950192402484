import { useEffect, useState } from 'react';
import { ApiPlaceType } from '../api-types';
import * as api from '../api';
import { createContainer } from 'unstated-next';

function usePlaceTypes() {
  const [placeTypes, setPlaceTypes] = useState<ApiPlaceType[]>([]);
  const [placeTypesLoading, setPlaceTypesLoading] = useState(false);

  useEffect(() => {
    fetchPlaceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlaceTypes = async () => {
    setPlaceTypesLoading(true);
    const data = await api.placeTypes();
    setPlaceTypes(data.results);
    setPlaceTypesLoading(false);
  };

  return {
    placeTypes,
    placeTypesLoading,
  };
}

export const PlaceTypesContainer = createContainer(usePlaceTypes);
