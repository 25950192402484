import { SearchTwoTone } from '@mui/icons-material';
import {
  Box,
  Chip,
  LinearProgress,
  List,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from '../common/utils';
import { ListItem } from '../components/ListItem';
import { usePageParams } from '../hooks/usePageParams';
import { StudentsContainer } from '../stores/Students';

export const PrintCertPage = () => {
  const { t } = useTranslation();
  const { page, setPage, size, setSize } = usePageParams();

  const {
    setStudentsPage,
    setStudentsSize,
    students,
    studentsTotal,
    studentsLoading,
  } = StudentsContainer.useContainer();

  useEffect(() => {
    setStudentsPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    setStudentsSize(size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <Box mt={1}>
      <Paper
        elevation={2}
        sx={{
          mb: [8, 8, 'unset'],
          position: [null, null, 'relative'],
          mt: [0, 2, 4],
        }}
      >
        {studentsLoading && <LinearProgress />}
        <List>
          {!studentsLoading && students?.length === 0 && (
            <ListItem
              icon={<SearchTwoTone />}
              primaryText={
                <Typography>{t('studentsPage.noRecords')}</Typography>
              }
            />
          )}
          {students.map((s) => (
            <ListItem
              key={s.id}
              primaryText={
                <>
                  {`${toTitleCase(s.first_name)} ${toTitleCase(s.last_name)}`}{' '}
                  <Chip
                    component="span"
                    color="secondary"
                    variant="outlined"
                    label={s.student_type.name}
                    size="small"
                  />
                </>
              }
              secondaryText={
                <>{`${toTitleCase(s.place.name)}${
                  s.school ? ' - ' + toTitleCase(s.school) : ''
                }`}</>
              }
              linkTo={`/cert/students/${s.id}`}
            />
          ))}
        </List>
        <TablePagination
          component="div"
          count={studentsTotal}
          page={page - 1}
          onPageChange={(e, v) => setPage(v + 1)}
          rowsPerPage={size}
          rowsPerPageOptions={window.PAGINATION_OPTS}
          onRowsPerPageChange={(e) => setSize(parseInt(e.target.value))}
          labelRowsPerPage={t('pagination.sizeLabel')}
          labelDisplayedRows={({ from, to, count }) =>
            t('pagination.displayLabel', { from, to, count })
          }
        />
      </Paper>
    </Box>
  );
};
