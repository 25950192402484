import {
  Alert,
  Box,
  Button,
  Grid,
  Grow,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { APIException } from '../common/exception';
import { toTitleCase } from '../common/utils';
import { useConfirm } from '../hooks/useConfirm';
import { AuthContainer } from '../stores/Auth';
import { PlaceContainer } from '../stores/Place';
import { PlacesContainer } from '../stores/Places';

export const PlaceDetailPage = () => {
  let id: number;
  const { id: idq } = useParams();
  if (idq) id = parseInt(idq, 10);
  const nav = useNavigate();
  const [error, setError] = useState<APIException>();
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();
  const { Dialog, ask } = useConfirm();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const { place, placeLoading } = PlaceContainer.useContainer();
  const { deletePlace } = PlacesContainer.useContainer();

  const handleDelete = async (id: number) => {
    try {
      const res = await ask(
        t('placesPage.deletePlace'),
        t('placesPage.deletePlaceContent'),
      );

      if (res) {
        await deletePlace(id);
        nav('/places');
      }
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <>
      <Dialog />
      <Box mt={1}>
        {error && error?.state?.general && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.state.general}
          </Alert>
        )}
        <Grow
          in={!placeLoading}
          style={{ transformOrigin: '0 0 0' }}
          timeout="auto"
        >
          <Paper
            elevation={2}
            sx={{
              mt: 2,
            }}
          >
            {!error && placeLoading && (
              <Box sx={{ pb: 2 }}>
                <LinearProgress />
              </Box>
            )}
            {place && (
              <Box sx={{ p: 2 }}>
                {/* <Grid container spacing={0.5}>
                <Grid item xs={5}>
                  <Typography variant="subtitle2" align="right" sx={{mt: 0.5, mr: 0.5}}>
                    {t('placeDetailPage.name')}:{' '}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="subtitle1">
                    {`${toTitleCase(place.name)}`}
                  </Typography>
                </Grid>
              </Grid>  */}
                <Typography variant="h6" align="center">
                  {`${toTitleCase(place.name)}`}
                </Typography>
                <Grid container spacing={0.5} sx={{ mt: 1 }}>
                  <Grid item xs={5}>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      color="textSecondary"
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      {t('placeDetailPage.type')}:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="subtitle1">
                      {`${toTitleCase(place.place_type.name)}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0.5}>
                  <Grid item xs={5}>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      color="textSecondary"
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      {t('placeDetailPage.branch')}:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="subtitle1">
                      {`${toTitleCase(place.branch.name)}`}
                    </Typography>
                  </Grid>
                </Grid>

                {place.neighborhood && (
                  <>
                    <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                      {place.neighborhood.district.city.title} -{' '}
                      {place.neighborhood.district.title}
                    </Typography>

                    <Typography variant="subtitle2" align="center">
                      {place.neighborhood?.title}
                    </Typography>
                  </>
                )}

                <Stack
                  direction={mdUp ? 'row' : 'column'}
                  spacing={1}
                  sx={{ mt: 3 }}
                >
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => nav(-1)}
                  >
                    {t('placeDetailPage.return')}
                  </Button>
                  {can('update_place') && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => nav(`/places/${id}/edit`)}
                    >
                      {t('placeDetailPage.edit')}
                    </Button>
                  )}
                  {can('delete_place') && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(id)}
                    >
                      {t('placeDetailPage.deletePlaceTitle')}
                    </Button>
                  )}
                </Stack>
              </Box>
            )}
          </Paper>
        </Grow>
      </Box>
    </>
  );
};
