import React from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';

export const SmallBox: React.FC<BoxProps> = (props) => {
  return (
    <Box
      {...props}
      sx={{
        bgcolor: 'grey.300',
        ...props.sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: 48,
        height: 48,
        p: 1,
        borderRadius: (t) => t.shape.borderRadius,
      }}
    >
      {props.children}
    </Box>
  );
};
