import { DeleteTwoTone } from '@mui/icons-material';
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

type ListItemProps = {
  primaryText: React.ReactNode;
  secondaryText?: React.ReactNode;
  icon?: React.ReactNode;
  onDelete?: () => void;
  linkTo?: string;
};

export const ListItem = ({
  icon,
  onDelete,
  primaryText,
  secondaryText,
  linkTo,
}: ListItemProps) => (
  <>
    <ListItemButton
      alignItems="flex-start"
      {...(linkTo ? { component: Link, to: linkTo } : {})}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primaryText} secondary={secondaryText} />
      {onDelete && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          >
            <DeleteTwoTone />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
    <Divider component="div" />
  </>
);
