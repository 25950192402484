import { useEffect, useState } from 'react';
import { ApiStudentType } from '../api-types';
import * as api from '../api';
import { createContainer } from 'unstated-next';

function useStudentTypes() {
  const [studentTypes, setStudentTypes] = useState<ApiStudentType[]>([]);
  const [studentTypesLoading, setStudentTypesLoading] = useState(false);

  useEffect(() => {
    fetchStudentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStudentTypes = async () => {
    setStudentTypesLoading(true);
    const data = await api.studentTypes();
    setStudentTypes(data.results);
    setStudentTypesLoading(false);
  };

  return {
    studentTypes,
    studentTypesLoading,
  };
}

export const StudentTypesContainer = createContainer(useStudentTypes);
