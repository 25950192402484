import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiTeacher, ApiTeacherInput } from '../api-types';
import { TeachersContainer } from './Teachers';

function useTeacher(initialState?: { id: number }) {
  const { teachers, setTeachers } = TeachersContainer.useContainer();
  const [teacher, setTeacher] = useState<ApiTeacher | null>(null);
  const [teacherLoading, setTeacherLoading] = useState(false);

  useEffect(() => {
    if (initialState?.id) fetchTeacher(initialState?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.id]);

  const fetchTeacher = async (id: number) => {
    if (teacherLoading) {
      return;
    }
    setTeacherLoading(true);
    const data = await api.teacher(id);
    setTeacher(data);
    setTeacherLoading(false);
  };

  const updateTeacher = async (id: number, data: ApiTeacherInput) => {
    setTeacherLoading(true);
    const updated = await api.updateTeacher(id, data);
    const newTeachers = teachers.map((s) => (s.id === id ? updated : s));
    if (teacher?.id === updated.id) setTeacher(updated);
    setTeachers(newTeachers);
    setTeacherLoading(false);
  };

  const enableTeacher = async (id: number) => {
    await api.enableTeacher(id);
    setTeacher({ ...teacher, is_active: true } as ApiTeacher);
  };

  const disableTeacher = async (id: number) => {
    await api.disableTeacher(id);
    setTeacher({ ...teacher, is_active: false } as ApiTeacher);
  };

  return {
    teacher,
    teacherLoading,
    fetchTeacher,
    updateTeacher,
    enableTeacher,
    disableTeacher,
  };
}

export const TeacherContainer = createContainer(useTeacher);
