import { useLayoutEffect as useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiTeacher } from '../api-types';
import { usePageParams } from '../hooks/usePageParams';

function useTeachers() {
  const [teachers, setTeachers] = useState<ApiTeacher[]>([]);
  const [total, setTotal] = useState(0);
  const { page, setPage, size, setSize } = usePageParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  const fetchTeachers = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const data = await api.teachers({
      page,
      size,
    });
    setTotal(data.count);
    setTeachers(data.results);
    setLoading(false);
  };

  const deleteTeacher = async (id: number) => {
    await api.deleteTeacher(id);
    setTeachers(teachers.filter((p) => p.id !== id));
  };

  return {
    teachers,
    loading,
    total,
    setTeachers,
    deleteTeacher,
    page,
    size,
    setPage,
    setSize,
  };
}

export const TeachersContainer = createContainer(useTeachers);
