import { Box, Paper, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as api from '../api';
import { APIException } from '../common/exception';
import { ResetPassForm, ResetPassFormProps } from '../components/ResetPassForm';

export const ResetPassPage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<APIException | null>();
  const [done, setDone] = useState(false);

  const handleSubmit: ResetPassFormProps['onSubmit'] = async (auth) => {
    setError(null);
    try {
      await api.resetPass(auth);
      setDone(true);
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
      } else setError({ message: 'Unknown error', status: 500 });
    }
  };

  return (
    <Box sx={{ pt: 4, maxWidth: '100%', width: 360, margin: '0 auto' }}>
      <Typography variant="h5">{t('resetPassPage.title')}</Typography>
      <Paper sx={{ mt: 2, p: 2 }}>
        {done ? (
          <Alert severity="success" sx={{ mt: 1 }}>
            {t('resetPassPage.successAlert')}
          </Alert>
        ) : (
          <>
            {error && error?.state?.general && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {error.state.general}
              </Alert>
            )}
            <ResetPassForm onSubmit={handleSubmit} error={error?.state?.form} />
          </>
        )}
        <Stack direction="row" justifyContent="flex-end" mt={2}>
          <Typography color="primary" component={Link} to="/login">
            {t('resetPassPage.login')}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};
