import { useEffect, useState } from 'react';
import { ApiTeacherType } from '../api-types';
import * as api from '../api';
import { createContainer } from 'unstated-next';

function useTeacherTypes() {
  const [teacherTypes, setTeacherTypes] = useState<ApiTeacherType[]>([]);
  const [teacherTypesLoading, setTeacherTypesLoading] = useState(false);

  useEffect(() => {
    fetchTeacherTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTeacherTypes = async () => {
    setTeacherTypesLoading(true);
    const data = await api.teacherTypes();
    setTeacherTypes(data.results);
    setTeacherTypesLoading(false);
  };

  return {
    teacherTypes,
    teacherTypesLoading,
  };
}

export const TeacherTypesContainer = createContainer(useTeacherTypes);
