import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import { ApiDoc } from '../api-types';

function useDocs() {
  const [docs, setDocs] = useState<ApiDoc[]>([]);
  const [docsLoading, setDocsLoading] = useState(false);

  const fetchDocs = async () => {
    setDocsLoading(true);
    const data = await api.docs();
    setDocs(data.results);
    setDocsLoading(false);
  };

  useEffect(() => {
    fetchDocs();
  }, []);

  return {
    docs,
    docsLoading,
  };
}

export const DocsContainer = createContainer(useDocs);
