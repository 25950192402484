import type {
  ApiCity,
  ApiDistrict,
  ApiList,
  ApiLoginResult,
  ApiNeighborhood,
  ApiPlace,
  ApiPlaceInput,
  ApiPlaceType,
  ApiRefreshTokenResult,
  ApiStudent,
  ApiStudentInput,
  ApiStudentType,
  ApiTeacher,
  ApiTeacherInput,
  ApiTeacherType,
  ApiUser,
  ApiPagingParams,
  ApiCountry,
  ApiCountryInput,
  ApiCityInput,
  ApiDistrictInput,
  ApiNeighborhoodInput,
  ApiDoc,
} from './api-types';

import { getCsrftoken, checkResponse } from './common/utils';
import { APIException, ErrorResponse } from './common/exception';

export async function handleResponse(
  response: Response,
  format: 'json' | 'text' | 'download' = 'json',
) {
  const isResponseOK = checkResponse(response);
  if (!isResponseOK) {
    const errorResponse: ErrorResponse = await response.json();
    throw new APIException(response.status, errorResponse);
  } else {
    if (response.status === 204) {
      return { status: 'SUCCESS' };
    }
    if (format === 'json') {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else if (format === 'text') {
      const textResponse = await response.text();
      return textResponse;
    }
  }
}

export async function resetPass(auth: {
  email: string;
}): Promise<ApiLoginResult> {
  const res = await fetch('/api/auth/password/reset/', {
    method: 'POST',
    body: JSON.stringify(auth),
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
  });

  return handleResponse(res);
}

export async function resetPassConfirm(auth: {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}): Promise<ApiLoginResult> {
  const res = await fetch('/api/auth/password/reset/confirm/', {
    method: 'POST',
    body: JSON.stringify(auth),
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
  });

  return handleResponse(res);
}

export async function login(auth: {
  email: string;
  username: string;
  password: string;
}): Promise<ApiLoginResult> {
  const res = await fetch('/api/auth/login/', {
    method: 'POST',
    body: JSON.stringify(auth),
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
  });

  return handleResponse(res);
}

export async function refreshToken(auth: {
  refresh: string;
}): Promise<ApiRefreshTokenResult> {
  const res = await fetch('/api/auth/token/refresh/', {
    method: 'POST',
    body: JSON.stringify(auth),
    headers: {
      'content-type': 'application/json',
    },
  });

  return handleResponse(res);
}

export function logout() {
  fetch('/api/auth/logout/', {
    method: 'POST',
  });
}

export async function currentUser(): Promise<ApiUser> {
  const res = await fetch('/api/auth/user/');
  return handleResponse(res);
}

export async function placeTypes(): Promise<ApiList<ApiPlaceType>> {
  const res = await fetch('/api/registry/place_types/');
  return handleResponse(res);
}

export async function places(params?: {
  page?: number;
  size?: number;
  search?: string;
}): Promise<ApiList<ApiPlace>> {
  const q = getListSearchParams(params);
  if (params?.search) q.set('search', params.search);
  const res = await fetch(`/api/registry/places/?${q.toString()}`);
  return handleResponse(res);
}

export async function place(id: number): Promise<ApiPlace> {
  const res = await fetch(`/api/registry/places/${id}/`);
  return handleResponse(res);
}

export async function addPlace(body: ApiPlaceInput): Promise<ApiPlace> {
  const res = await fetch('/api/registry/places/', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
    body: JSON.stringify(body),
  });
  return handleResponse(res);
}

export async function updatePlace(
  id: number,
  body: ApiPlaceInput,
): Promise<ApiPlace> {
  const res = await fetch(`/api/registry/places/${id}/`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
    body: JSON.stringify(body),
  });
  return handleResponse(res);
}

export async function deletePlace(id: number) {
  const res = await fetch(`/api/registry/places/${id}/`, { method: 'DELETE' });
  return handleResponse(res);
}

export async function countries(params?: {
  search?: string;
}): Promise<ApiList<ApiCountry>> {
  const q = new URLSearchParams(params || {});
  const res = await fetch(`/api/geo/countries/?${q.toString()}`);
  return handleResponse(res);
}

export async function addCountry(data: ApiCountryInput): Promise<ApiCountry> {
  const res = await fetch(`/api/geo/countries/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  });
  return handleResponse(res);
}

export async function cities(params?: {
  country: string;
  search?: string;
}): Promise<ApiList<ApiCity>> {
  const q = new URLSearchParams(params);
  const res = await fetch(`/api/geo/cities/?${q.toString()}`);
  return handleResponse(res);
}

export async function addCity(data?: ApiCityInput): Promise<ApiCity> {
  const res = await fetch(`/api/geo/cities/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  });
  return handleResponse(res);
}

export async function districts(params?: {
  city: string;
  search?: string;
}): Promise<ApiList<ApiDistrict>> {
  const q = new URLSearchParams(params);
  const res = await fetch(`/api/geo/districts?${q.toString()}`);
  return handleResponse(res);
}

export async function addDistrict(
  data?: ApiDistrictInput,
): Promise<ApiDistrict> {
  const res = await fetch(`/api/geo/districts/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  });
  return handleResponse(res);
}

export async function neighborhoods(params: {
  district: string;
  search?: string;
}): Promise<ApiList<ApiNeighborhood>> {
  const q = new URLSearchParams(params);
  const res = await fetch(`/api/geo/neighborhoods?${q.toString()}`);
  return handleResponse(res);
}

export async function addNeighborhood(
  data?: ApiNeighborhoodInput,
): Promise<ApiNeighborhood> {
  const res = await fetch(`/api/geo/neighborhoods/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  });
  return handleResponse(res);
}

export async function studentTypes(): Promise<ApiList<ApiStudentType>> {
  const res = await fetch('/api/registry/student_types/');
  return handleResponse(res);
}

const getListSearchParams = (params?: ApiPagingParams) => {
  const q = new URLSearchParams();
  if (!params) return q;
  if (params.page && params.size) {
    q.set('offset', String((params.page - 1) * params.size));
  }
  if (params.size) q.set('limit', String(params.size));
  return q;
};

export async function students(
  params?: ApiPagingParams,
): Promise<ApiList<ApiStudent>> {
  const q = getListSearchParams(params);
  const res = await fetch(`/api/registry/students/?${q.toString()}`);
  return handleResponse(res);
}

export async function student(id: number): Promise<ApiStudent> {
  const res = await fetch(`/api/registry/students/${id}/`);
  return handleResponse(res);
}

export async function addStudent(body: ApiStudentInput): Promise<ApiStudent> {
  const res = await fetch('/api/registry/students/', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
    body: JSON.stringify(body),
  });
  return handleResponse(res);
}

export async function updateStudent(
  id: number,
  body: ApiStudentInput,
): Promise<ApiStudent> {
  const res = await fetch(`/api/registry/students/${id}/`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
    body: JSON.stringify(body),
  });
  return handleResponse(res);
}

export async function printCert(studentId: number, design: number) {
  const res = await fetch(`/api/registry/students/${studentId}/certificate/`, {
    method: 'POST',
    body: JSON.stringify({ design }),
    headers: {
      'content-type': 'application/json',
    },
  });
  return res;
}

export async function deleteStudent(id: number) {
  const res = await fetch(`/api/registry/students/${id}/`, {
    method: 'DELETE',
  });
  return handleResponse(res);
}

export async function teacherTypes(): Promise<ApiList<ApiTeacherType>> {
  const res = await fetch('/api/auth_manage/teacher_user_types/');
  return handleResponse(res);
}

export async function teachers(
  params?: ApiPagingParams,
): Promise<ApiList<ApiTeacher>> {
  const q = getListSearchParams(params);
  const res = await fetch(`/api/auth_manage/teacher_users/?${q.toString()}`);
  return handleResponse(res);
}

export async function teacher(id: number): Promise<ApiTeacher> {
  const res = await fetch(`/api/auth_manage/teacher_users/${id}/`);
  return handleResponse(res);
}

export async function addTeacher(body: ApiTeacherInput): Promise<ApiTeacher> {
  const res = await fetch('/api/auth_manage/teacher_users/', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
    body: JSON.stringify(body),
  });
  return handleResponse(res);
}

export async function updateTeacher(
  id: number,
  body: ApiTeacherInput,
): Promise<ApiTeacher> {
  const res = await fetch(`/api/auth_manage/teacher_users/${id}/`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'X-CSRFToken': getCsrftoken() || '',
    },
    body: JSON.stringify(body),
  });
  return handleResponse(res);
}

export async function deleteTeacher(id: number) {
  const res = await fetch(`/api/auth_manage/teacher_users/${id}/`, {
    method: 'DELETE',
  });
  return handleResponse(res);
}

export async function enableTeacher(id: number) {
  const res = await fetch(`/api/auth_manage/teacher_users/${id}/enable/`, {
    method: 'POST',
  });
  return handleResponse(res);
}

export async function disableTeacher(id: number) {
  const res = await fetch(`/api/auth_manage/teacher_users/${id}/disable/`, {
    method: 'POST',
  });
  return handleResponse(res);
}

export async function docs(): Promise<ApiList<ApiDoc>> {
  const res = await fetch('/api/registry/docs');
  return handleResponse(res);
}
