import { Button, TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIFormError } from '../common/exception';

export type LoginFormProps = {
  onSubmit: (auth: {
    email: string;
    username: string;
    password: string;
  }) => void;
  error?: APIFormError;
};

export const LoginForm = ({ onSubmit, error }: LoginFormProps) => {
  const { t } = useTranslation();
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const submitObj = { password, username: '', email: '' };
    if (emailOrUsername.includes('@')) {
      submitObj.email = emailOrUsername;
    } else {
      submitObj.username = emailOrUsername;
    }
    onSubmit(submitObj);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          id="emailOrUsername"
          label={t('loginForm.emailOrUsername')}
          variant="outlined"
          type="text"
          value={emailOrUsername}
          onChange={(e) => setEmailOrUsername(e.target.value)}
          sx={{ width: '100%' }}
          required
          fullWidth
          error={error && error.fields?.emailOrUsername !== undefined}
          helperText={error && error.fields?.emailOrUsername}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          id="password"
          label={t('loginForm.password')}
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ width: '100%' }}
          required
          fullWidth
          error={error && error.fields?.password !== undefined}
          helperText={error && error.fields?.password}
        />
      </Box>
      <Button fullWidth variant="contained" color="primary" type="submit">
        {t('loginForm.submit')}
      </Button>
      {error && error.general && (
        <FormHelperText error={true} sx={{ mt: 2 }}>
          {error.general}
        </FormHelperText>
      )}
    </form>
  );
};
