import { useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiTeacherInput } from '../api-types';
import { APIException } from '../common/exception';

function useTeacherAdd() {
  const [error, setError] = useState<APIException>();
  const [submitting, setSubmitting] = useState(false);

  const addTeacher = async (data: ApiTeacherInput) => {
    try {
      setSubmitting(true);
      const added = await api.addTeacher(data);
      setSubmitting(false);
      return added;
    } catch (err) {
      setSubmitting(false);
      if (err instanceof APIException) {
        setError(err);
        console.error(err.message);
      }
    }
  };

  return {
    error,
    submitting,
    addTeacher,
  };
}

export const TeacherAddContainer = createContainer(useTeacherAdd);
