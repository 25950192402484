import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuthContainer } from '../stores/Auth';

function Account() {
  const { user } = AuthContainer.useContainer();
  const { t } = useTranslation();

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          p: 2,
          mt: 2,
        }}
      >
        <Box>
          <Typography variant="subtitle2" color="textSecondary" align="center">
            {t('account.username')}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {user?.username}
          </Typography>
        </Box>
        {user?.first_name && user?.last_name && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              {t('account.full_name')}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {user?.first_name + ' ' + user?.last_name}
            </Typography>
          </Box>
        )}
        {user?.branch && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              {t('account.branch')}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {user?.branch.name}
            </Typography>
          </Box>
        )}
      </Paper>
    </>
  );
}

export default Account;
