import { createTheme } from '@mui/material';
import '@fontsource/baloo-thambi-2';
import '@fontsource/nunito-sans';

const nunito = [
  'Nunito Sans',
  '-apple-system',
  '"Segoe UI"',
  'BlinkMacSystemFont',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const balooThambi = ['Baloo Thambi 2', 'cursive'].join(',');

export const themes = [
  createTheme({
    typography: {
      fontFamily: nunito,
    },
    palette: {
      primary: {
        main: '#00796b',
      },
      secondary: {
        main: '#fb8c00',
      },
    },
  }),
  createTheme({
    typography: {
      fontFamily: balooThambi,
    },
    palette: {
      primary: {
        main: '#607d8b',
      },
      secondary: {
        main: '#ff8a65',
      },
    },
  }),
  createTheme({
    typography: {
      fontFamily: nunito,
    },
    palette: {
      primary: {
        main: '#1890ff',
      },
      secondary: {
        main: '#8c8c8c',
      },
    },
  }),
];
