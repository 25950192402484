import {
  FolderOpenTwoTone,
  MapsHomeWorkTwoTone,
  MenuBookTwoTone,
  PeopleTwoTone,
  StarTwoTone,
} from '@mui/icons-material';
import { Badge, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContainer } from '../stores/Auth';

export const BottomNav = () => {
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();
  const loc = useLocation();
  const nav = useNavigate();
  const pathEls = loc.pathname.split('/');
  const firstPath = pathEls.length >= 1 ? pathEls[1] : '';

  return (
    <BottomNavigation
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      showLabels
      value={firstPath}
      onChange={(e, val) => nav('/' + val)}
    >
      {can('list_place') && (
        <BottomNavigationAction
          label={t('navList.places')}
          icon={<MapsHomeWorkTwoTone />}
          value="places"
        />
      )}
      {can('list_teacheruser') && (
        <BottomNavigationAction
          label={t('navList.teachers')}
          icon={<MenuBookTwoTone />}
          value="teachers"
        />
      )}
      {can('list_student') && (
        <BottomNavigationAction
          label={t('navList.students')}
          icon={<PeopleTwoTone />}
          value="students"
        />
      )}
      {can('list_doc') && (
        <BottomNavigationAction
          label={t('navList.docs')}
          icon={
            <Badge badgeContent={t('global.new')} color="error">
              <FolderOpenTwoTone />
            </Badge>
          }
          value="docs"
        />
      )}
      {can('print_cert') && (
        <BottomNavigationAction
          label={t('navListMobile.printCert')}
          icon={
            <Badge badgeContent={t('global.new')} color="error">
              <StarTwoTone />
            </Badge>
          }
          value="cert"
        />
      )}
    </BottomNavigation>
  );
};
