import {
  FolderOpenTwoTone,
  MapsHomeWorkTwoTone,
  MenuBookTwoTone,
  PeopleTwoTone,
  PersonTwoTone,
  StarTwoTone,
} from '@mui/icons-material';
import {
  Badge,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { AuthContainer } from '../stores/Auth';

const ListItemLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });
  return (
    <ListItemButton
      component={Link}
      to={to}
      sx={{
        mb: 0.5,
        borderRadius: 2,
        backgroundColor: match ? 'primary.main' : null,
        color: (t) =>
          match ? t.palette.getContrastText(t.palette.primary.main) : null,
        '&:hover': {
          background: (t) => (match ? t.palette.primary.light : null),
        },
        '.MuiListItemIcon-root': {
          color: (t) =>
            match ? t.palette.getContrastText(t.palette.primary.main) : null,
        },
      }}
      {...props}
    >
      {children}
    </ListItemButton>
  );
};

export const NavList = () => {
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();

  return (
    <List dense>
      {can('list_place') && (
        <ListItemLink to="/places">
          <ListItemIcon>
            <MapsHomeWorkTwoTone />
          </ListItemIcon>
          <ListItemText primary={t('navList.places')} />
        </ListItemLink>
      )}
      {can('list_teacheruser') && (
        <ListItemLink to="/teachers">
          <ListItemIcon>
            <MenuBookTwoTone />
          </ListItemIcon>
          <ListItemText primary={t('navList.teachers')} />
        </ListItemLink>
      )}
      {can('list_student') && (
        <ListItemLink to="/students">
          <ListItemIcon>
            <PeopleTwoTone />
          </ListItemIcon>
          <ListItemText primary={t('navList.students')} />
        </ListItemLink>
      )}
      {can('list_doc') && (
        <ListItemLink to="/docs">
          <ListItemIcon>
            <FolderOpenTwoTone />
          </ListItemIcon>
          <Badge badgeContent={t('global.new')} color="error">
            <ListItemText primary={t('navList.docs')} />
          </Badge>
        </ListItemLink>
      )}
      {can('print_cert') && (
        <ListItemLink to="/cert">
          <ListItemIcon>
            <StarTwoTone />
          </ListItemIcon>
          <Badge badgeContent={t('global.new')} color="error">
            <ListItemText primary={t('navList.printCert')} />
          </Badge>
        </ListItemLink>
      )}
      <ListItemLink to="/account">
        <ListItemIcon>
          <PersonTwoTone />
        </ListItemIcon>
        <ListItemText primary={t('navList.account')} />
      </ListItemLink>
    </List>
  );
};
