import {
  Alert,
  Box,
  Hidden,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { TeacherFormProps } from '../components/TeacherForm';
import { TeacherForm } from '../components/TeacherForm';
import { TeacherAddContainer } from '../stores/TeacherAdd';
import { TeacherTypesContainer } from '../stores/TeacherTypes';

const TeacherAddPageWithData = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const { teacherTypes } = TeacherTypesContainer.useContainer();
  const { submitting, error, addTeacher } = TeacherAddContainer.useContainer();

  const handleSave: TeacherFormProps['onSubmit'] = async (values) => {
    const res = await addTeacher({
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.phone_number,
      teacher_user_type: values.teacher_user_type,
      places: values.places.map((p) => p.id),
    });
    if (res) nav(`/teachers/${res.id}/detail`);
  };

  return (
    <>
      <Box mt={1}>
        <Hidden mdDown>
          <Typography variant="h5">{t('teacherAddPage.title')}</Typography>
        </Hidden>
        {error && error?.state?.general && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.state.general}
          </Alert>
        )}
        <Paper
          elevation={2}
          sx={{
            mt: 2,
          }}
        >
          {submitting && <LinearProgress />}
          <Box p={2}>
            <TeacherForm
              teacherTypes={teacherTypes}
              disabled={submitting}
              onSubmit={handleSave}
              onCancel={() => nav(-1)}
              error={error?.state?.form}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export const TeacherAddPage = () => (
  <TeacherAddContainer.Provider>
    <TeacherAddPageWithData />
  </TeacherAddContainer.Provider>
);
