import { StarTwoTone } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Grid,
  Grow,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { APIException } from '../common/exception';
import { isDisabledField, toTitleCase } from '../common/utils';
import { SmallBox } from '../components/SmallBox';
import { StudentGender } from '../components/StudentGender';
import { StudentYearInSchool } from '../components/StudentYearInSchool';
import { useConfirm } from '../hooks/useConfirm';
import { AuthContainer } from '../stores/Auth';
import { StudentContainer } from '../stores/Student';
import { StudentsContainer } from '../stores/Students';

export const StudentDetailPage = () => {
  let id: number;
  const { id: idq } = useParams();
  if (idq) id = parseInt(idq, 10);
  const nav = useNavigate();
  const [error, setError] = useState<APIException>();
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();
  const { Dialog, ask } = useConfirm();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const { student, studentLoading } = StudentContainer.useContainer();

  const { deleteStudent } = StudentsContainer.useContainer();

  const handleDelete = async (id: number) => {
    try {
      const res = await ask(
        t('studentDetailPage.deleteStudent'),
        t('studentDetailPage.deleteStudentContent'),
      );

      if (res) {
        deleteStudent(id);
        nav('/students');
      }
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <>
      <Dialog />
      <Box mt={1}>
        {error && error?.state?.general && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.state.general}
          </Alert>
        )}
        <Grow
          in={!studentLoading}
          style={{ transformOrigin: '0 0 0' }}
          timeout="auto"
        >
          <Paper
            elevation={2}
            sx={{
              mt: 2,
            }}
          >
            {!error && studentLoading && (
              <Box sx={{ pb: 2 }}>
                <LinearProgress />
              </Box>
            )}
            {student && (
              <Box sx={{ p: 2 }}>
                {/* <Grid container spacing={0.5}>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="right" sx={{mt: 0.5, mr: 0.5}}>
										{t('studentDetailPage.firstName')}:{' '}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle1">
									{`${toTitleCase(student.first_name)}`}
									</Typography>
								</Grid>
							</Grid> 
							<Grid container spacing={0.5}>
								<Grid item xs={6}>
									<Typography variant="subtitle2" align="right" sx={{mt: 0.5, mr: 0.5}}>
										{t('studentDetailPage.lastName')}:{' '}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="subtitle1">
										{`${toTitleCase(student.last_name)}`}
									</Typography>
								</Grid>
							</Grid>  */}
                <Typography variant="h6" align="center">
                  {`${toTitleCase(student.first_name)}`}{' '}
                  {`${toTitleCase(student.last_name)}`}
                </Typography>
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {!isDisabledField('year_in_school') &&
                    student.year_in_school && (
                      <SmallBox sx={{ mr: 2, bgcolor: 'secondary.light' }}>
                        <Typography variant="body2">
                          <StudentYearInSchool>
                            {student.year_in_school}
                          </StudentYearInSchool>
                        </Typography>
                      </SmallBox>
                    )}
                  <SmallBox
                    sx={{
                      bgcolor: 'primary.light',
                      color: 'primary.contrastText',
                      mr: 2,
                    }}
                  >
                    <Typography variant="body2">
                      <StudentGender>{student.gender}</StudentGender>
                    </Typography>
                  </SmallBox>
                  {!isDisabledField('age') && student.age && (
                    <SmallBox>
                      <Box>
                        <Typography variant="body2">
                          {t('studentDetailPage.age', { age: student.age })}
                        </Typography>
                      </Box>
                    </SmallBox>
                  )}
                </Box>

                {!isDisabledField('school') && student.school && (
                  <Grid container spacing={0.5} mt={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        color="textSecondary"
                        sx={{ mt: 0.5, mr: 0.5 }}
                      >
                        {t('studentDetailPage.school')}:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {`${toTitleCase(student.school)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isDisabledField('guardian_name') && student.guardian_name && (
                  <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        color="textSecondary"
                        sx={{ mt: 0.5, mr: 0.5 }}
                      >
                        {t('studentDetailPage.guardianName')}:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {`${toTitleCase(student.guardian_name)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isDisabledField('guardian_phone_number') &&
                  student.guardian_phone_number && (
                    <Grid container spacing={0.5}>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          align="right"
                          color="textSecondary"
                          sx={{ mt: 0.5, mr: 0.5 }}
                        >
                          {t('studentDetailPage.guardianPhoneNumber')}:{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">
                          {student.guardian_phone_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                {!isDisabledField('phone_number') && student.phone_number && (
                  <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        color="textSecondary"
                        sx={{ mt: 0.5, mr: 0.5 }}
                      >
                        {t('studentDetailPage.phoneNumber')}:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {student.phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={0.5}>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      color="textSecondary"
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      {t('studentDetailPage.studentType')}:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      {student.student_type.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0.5}>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      color="textSecondary"
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      {t('studentDetailPage.place')}:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      {`${toTitleCase(student.place.name)}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Stack
                  direction={mdUp ? 'row' : 'column'}
                  spacing={1}
                  sx={{ mt: 3 }}
                >
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => nav(-1)}
                  >
                    {t('studentDetailPage.return')}
                  </Button>
                  {can('update_student') && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => nav(`/students/${id}/edit`)}
                    >
                      {t('studentDetailPage.edit')}
                    </Button>
                  )}
                  {can('print_cert') && (
                    <Box sx={{ pr: mdUp ? 2 : 0 }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        component={Link}
                        to={`/cert/students/${student.id}`}
                        startIcon={<StarTwoTone />}
                        fullWidth
                      >
                        {t('studentDetailPage.printCert')}
                      </Button>
                    </Box>
                  )}
                  {can('delete_student') && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(id)}
                    >
                      {t('studentDetailPage.deleteStudentTitle')}
                    </Button>
                  )}
                </Stack>
              </Box>
            )}
          </Paper>
        </Grow>
      </Box>
    </>
  );
};
