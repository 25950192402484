import { InsertDriveFileTwoTone } from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiDoc } from '../api-types';
import { useContainer } from 'unstated-next';
import { DocsContainer } from '../stores/Docs';

declare let GA_ENABLED: boolean;
declare let gtag: (
  ob: 'event',
  action: string,
  meta?: { [key: string]: string },
) => void;

export const DocsPage = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState<string>();
  const { docs, docsLoading } = useContainer(DocsContainer);

  const handleDocClick = async (d: ApiDoc) => {
    const u = new URL(d.file);
    if (GA_ENABLED) {
      const [filename, ext] = (u.pathname.split('/').pop() || '').split('.');
      gtag('event', 'file_download', {
        file_extension: `.${ext}`,
        file_name: filename,
        link_url: u.href,
        link_text: `${filename}.${ext}`,
      });
    }

    if (mdUp) {
      window.open(u.href, '_blank');
    } else {
      setDownloading(d.file);
      console.log('downloading');
      // download file on mobile devices
      const res = await fetch(u.href);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = u.pathname.split('/').pop() || '';
      //a.click();
      a.dispatchEvent(new MouseEvent('click'));
      document.body.removeChild(a);
      setDownloading(undefined);
    }
  };

  return (
    <Box mt={1}>
      <Paper
        elevation={2}
        sx={{
          mt: 2,
        }}
      >
        {docsLoading ? (
          <LinearProgress />
        ) : docs.length === 0 ? (
          <Box p={2}>
            <Alert severity="warning">{t('docsPage.noDocs')}</Alert>
          </Box>
        ) : (
          <List>
            {docs.map((doc) => (
              <ListItem
                button
                key={doc.file}
                onClick={() => handleDocClick(doc)}
              >
                <ListItemIcon>
                  {downloading === doc.file ? (
                    <CircularProgress size={24} />
                  ) : (
                    <InsertDriveFileTwoTone />
                  )}
                </ListItemIcon>
                <ListItemText primary={doc.name} />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Box>
  );
};
