import { Box, Hidden, LinearProgress, Paper, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { APIException } from '../common/exception';
import type { PlaceFormProps } from '../components/PlaceForm';
import { PlaceForm } from '../components/PlaceForm';
import { PlaceContainer } from '../stores/Place';
import { PlaceTypesContainer } from '../stores/PlaceTypes';

export const PlaceEditPage = () => {
  const { id: idq } = useParams();
  let id: number;
  if (idq) id = parseInt(idq, 10);

  const { t } = useTranslation();
  const nav = useNavigate();
  const { placeTypes, placeTypesLoading } = PlaceTypesContainer.useContainer();
  const [error, setError] = useState<APIException>();

  const { place, updatePlace, placeLoading } = PlaceContainer.useContainer();

  const handleSave: PlaceFormProps['onSubmit'] = async (values) => {
    try {
      if (!place) return;
      await updatePlace(id, {
        name: values.name,
        place_type: values.typeId,
        neighborhood: values.neighborhoodId,
      });
      nav('/places');
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <Box mt={1}>
      <Hidden mdDown>
        <Typography variant="h5">{t('placeEditPage.title')}</Typography>
      </Hidden>
      {error && error?.state?.general && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error.state.general}
        </Alert>
      )}
      <Paper
        elevation={2}
        sx={{
          mt: 2,
        }}
      >
        {!error && (placeLoading || placeTypesLoading) && <LinearProgress />}
        {place && (
          <Box p={2}>
            <PlaceForm
              placeTypes={placeTypes}
              initialValues={{
                name: place.name,
                typeId: place.place_type.id,
                neighborhood: place.neighborhood,
              }}
              onSubmit={handleSave}
              onCancel={() => nav(-1)}
              error={error?.state?.form}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};
