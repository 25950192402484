import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import { ApiUser } from '../api-types';
import { getCookie } from '../common/utils';

enum PermissionType {
  'list_place' = 'registry.view_place',
  'add_place' = 'registry.add_place',
  'update_place' = 'registry.change_place',
  'delete_place' = 'registry.delete_place',
  'list_teacheruser' = 'custom_auth.view_teacheruser',
  'add_teacheruser' = 'custom_auth.add_teacheruser',
  'update_teacheruser' = 'custom_auth.change_teacheruser',
  'delete_teacheruser' = 'custom_auth.delete_teacheruser',
  'list_student' = 'registry.view_student',
  'add_student' = 'registry.add_student',
  'update_student' = 'registry.change_student',
  'delete_student' = 'registry.delete_student',
  'list_country' = 'regions.view_country',
  'add_country' = 'regions.add_country',
  'update_country' = 'regions.change_country',
  'delete_country' = 'regions.delete_country',
  'list_city' = 'regions.view_city',
  'add_city' = 'regions.add_city',
  'update_city' = 'regions.change_city',
  'delete_city' = 'regions.delete_city',
  'list_district' = 'regions.view_district',
  'add_district' = 'regions.add_district',
  'update_district' = 'regions.change_district',
  'delete_district' = 'regions.delete_district',
  'list_neighborhood' = 'regions.view_neighborhood',
  'add_neighborhood' = 'regions.add_neighborhood',
  'update_neighborhood' = 'regions.change_neighborhood',
  'delete_neighborhood' = 'regions.delete_neighborhood',
  'list_doc' = 'auth.list_doc',
  'print_cert' = 'auth.print_cert',
}

function useAuth() {
  const [isAuthCheck, setIsAuthCheck] = useState(false);
  const [user, setUser] = useState<ApiUser | null>();

  useEffect(() => {
    refreshToken();
  }, []);

  const login = async (auth: {
    email: string;
    username: string;
    password: string;
  }) => {
    const res = await api.login(auth);
    console.log(res.user);
    setUser(res.user);
  };

  const logout = () => {
    api.logout();
    setUser(null);
  };

  const refreshToken = async () => {
    const refresh = getCookie('glojuniour-refresh-token');
    if (refresh) {
      await api.refreshToken({ refresh });
      const user = await api.currentUser();
      setUser(user);
      setIsAuthCheck(true);
    } else {
      setIsAuthCheck(true);
    }
  };

  const can = (permission: keyof typeof PermissionType) => {
    const permissionMapped = PermissionType[permission];
    if (user && user.permissions) {
      return user.permissions.includes(permissionMapped);
    }
    return false;
  };

  return {
    login,
    logout,
    can,
    user,
    isAuthCheck,
  };
}

export const AuthContainer = createContainer(useAuth);
