/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type _PhoneInputProps = {
  initialValue: string | null;
  onChange: (value: string | null) => void;
  [k: string]: any;
};

function TextFieldRef(props: any, ref: React.Ref<any>) {
  return <TextField {...props} inputRef={ref} />;
}

const PhoneInputComponent = forwardRef(TextFieldRef);

export function PhoneInput({
  initialValue,
  onChange,
  error,
  helperText,
  ...rest
}: _PhoneInputProps) {
  const [value, setValue] = useState(initialValue);
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const { t } = useTranslation();

  function handleChange(v: string) {
    setValue(v);
    if (!v) return;

    if (isValidPhoneNumber(v)) {
      setErrorMsg(null);
      onChange(v);
    } else {
      onChange(null);
      setErrorMsg(t('phoneInput.invalid'));
    }
  }

  return (
    <RPhoneInput
      defaultCountry="TR"
      value={value || ''}
      onChange={handleChange}
      inputComponent={PhoneInputComponent}
      coun
      error={error || Boolean(errorMsg)}
      helperText={helperText || errorMsg}
      {...rest}
    />
  );
}
