import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApiCity,
  ApiCountry,
  ApiDistrict,
  ApiNeighborhood,
  ApiPlaceType,
} from '../api-types';
import { APIFormError } from '../common/exception';
import { AuthContainer } from '../stores/Auth';
import { CityAC } from './CityAC';
import { CountryAC } from './CountryAC';
import { DistrictAC } from './DistrictAC';
import { NeighborhoodAC } from './NeighborhoodAC';

declare const LOCKED_COUNTRY_IDS: string[];

export type PlaceFormProps = {
  initialValues?: {
    name: string;
    typeId: number;
    neighborhood: ApiNeighborhood | null;
  };
  placeTypes: ApiPlaceType[];
  onSubmit: (values: {
    name: string;
    typeId: number;
    neighborhoodId: number | null;
  }) => void;
  onCancel: () => void;
  error?: APIFormError;
};

export const PlaceForm = ({
  initialValues,
  placeTypes,
  onSubmit,
  onCancel,
  error,
}: PlaceFormProps) => {
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();
  const [name, setName] = useState(initialValues?.name || '');
  const [typeId, setTypeId] = useState<number | null>(
    initialValues?.typeId || null,
  );
  const [country, setCountry] = useState<ApiCountry | null>(
    initialValues?.neighborhood?.district?.city?.country || {
      id: 1,
      title: 'Türkiye',
    },
  );
  const [city, setCity] = useState<ApiCity | null>(
    initialValues?.neighborhood?.district?.city || null,
  );
  const [district, setDistrict] = useState<ApiDistrict | null>(
    initialValues?.neighborhood?.district || null,
  );
  const [neighborhood, setNeighboorhood] = useState<ApiNeighborhood | null>(
    initialValues?.neighborhood || null,
  );
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!typeId || !neighborhood) return;
    onSubmit({
      name,
      typeId,
      neighborhoodId: neighborhood?.id,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          id="name"
          label={t('placeForm.name')}
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ width: ['100%', '100%', '100%'] }}
          required
          error={error && error.fields?.name !== undefined}
          helperText={error && error.fields?.name}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <FormControl sx={{ width: ['100%', '100%', '100%'] }}>
          <InputLabel id="type">{t('placeForm.type')}</InputLabel>
          <Select
            labelId="type"
            id="type-select"
            value={typeId}
            label={t('placeForm.type')}
            onChange={(e) =>
              e.target.value && setTypeId(parseInt(String(e.target.value)))
            }
            required
          >
            {placeTypes.map((pt) => (
              <MenuItem key={pt.id} value={pt.id}>
                {pt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ mb: 2 }}>
        <CountryAC
          value={country}
          onChange={(v) => {
            setCountry(v);
            setCity(null);
            setDistrict(null);
            setNeighboorhood(null);
          }}
          allowCreate={can('add_country')}
        />
      </Box>

      {country && (
        <Box sx={{ mb: 2 }}>
          <CityAC
            country={country}
            value={city}
            onChange={(v) => {
              setCity(v);
              setDistrict(null);
              setNeighboorhood(null);
            }}
            allowCreate={
              can('add_city') &&
              !LOCKED_COUNTRY_IDS.includes(String(country.id))
            }
          />
        </Box>
      )}

      {country && city && (
        <Box sx={{ mb: 2 }}>
          <DistrictAC
            city={city}
            value={district}
            onChange={(v) => {
              setDistrict(v);
              setNeighboorhood(null);
            }}
            allowCreate={
              can('add_district') &&
              !LOCKED_COUNTRY_IDS.includes(String(country.id))
            }
          />
        </Box>
      )}

      {country && district && (
        <Box sx={{ mb: 2 }}>
          <NeighborhoodAC
            district={district}
            value={neighborhood}
            onChange={(v) => setNeighboorhood(v)}
            allowCreate={
              can('add_neighborhood') &&
              !LOCKED_COUNTRY_IDS.includes(String(country.id))
            }
          />
        </Box>
      )}

      <Stack direction={mdUp ? 'row' : 'column'} spacing={1}>
        <Button color="secondary" type="button" onClick={onCancel}>
          {t('form.cancel')}
        </Button>

        <Button variant="contained" color="primary" type="submit">
          {t('form.save')}
        </Button>
      </Stack>
      {error && error.general && (
        <FormHelperText error={true} sx={{ mt: 2 }}>
          {error.general}
        </FormHelperText>
      )}
    </form>
  );
};
