import MoreIcon from '@mui/icons-material/MoreVert';
import {
  AppBar as AppBarx,
  Button,
  Container,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ApiUser } from '../api-types';

declare let APP_TITLE: string;
declare let APP_LOGO_URL: string;
declare let APP_LOGO_2X_URL: string;

export type AppBarProps = {
  user: ApiUser | null | undefined;
  onLogout: () => void;
};

export function AppBar({ user, onLogout }: AppBarProps) {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const nav = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const handleUserMenuClose = () => setUserMenuAnchorEl(null);
  const handleLogout = () => {
    handleUserMenuClose();
    onLogout();
  };

  let title = '';
  if (location.pathname.endsWith('/students/new')) {
    title = t('appBar.studentNew');
  } else if (location.pathname.endsWith('/teachers/new')) {
    title = t('appBar.teacherNew');
  } else if (location.pathname.endsWith('/places/new')) {
    title = t('appBar.placeNew');
  } else if (location.pathname.endsWith('/places')) {
    title = t('appBar.places');
  } else if (location.pathname.endsWith('/students')) {
    title = t('appBar.students');
  } else if (location.pathname.endsWith('/teachers')) {
    title = t('appBar.teachers');
  } else if (location.pathname.endsWith('/account')) {
    title = t('appBar.profile');
  } else if (
    location.pathname.startsWith('/places') &&
    location.pathname.endsWith('/detail')
  ) {
    title = t('appBar.placeDetail');
  } else if (
    location.pathname.startsWith('/places') &&
    location.pathname.endsWith('/edit')
  ) {
    title = t('appBar.placeEdit');
  } else if (
    location.pathname.startsWith('/teachers') &&
    location.pathname.endsWith('/detail')
  ) {
    title = t('appBar.teacherDetail');
  } else if (
    location.pathname.startsWith('/teachers') &&
    location.pathname.endsWith('/edit')
  ) {
    title = t('appBar.teacherEdit');
  } else if (
    location.pathname.startsWith('/students') &&
    location.pathname.endsWith('/detail')
  ) {
    title = t('appBar.studentDetail');
  } else if (
    location.pathname.startsWith('/students') &&
    location.pathname.endsWith('/edit')
  ) {
    title = t('appBar.studentEdit');
  } else if (location.pathname.startsWith('/cert')) {
    title = t('appBar.studentCert');
  } else if (location.pathname.startsWith('/docs')) {
    title = t('appBar.docs');
  }

  return (
    <AppBarx
      position="static"
      elevation={1}
      sx={{
        bgcolor: ['primary', 'transparent'],
        color: ['#fff', 'text.primary'],
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Hidden mdUp>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
          </Hidden>
          <Hidden mdDown>
            <Box sx={{ flexGrow: 1 }}>
              <img
                src={APP_LOGO_URL}
                alt={`${APP_TITLE} Logo`}
                srcSet={`${APP_LOGO_2X_URL} 2x`}
                width="120"
              />
            </Box>
          </Hidden>
          {!user ? (
            <Button onClick={() => nav('/login')}>{t('appBar.login')}</Button>
          ) : (
            <div>
              <Hidden mdDown>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    onClick={handleLogout}
                    sx={{ my: 2, display: 'block' }}
                  >
                    {t('appBar.logout')}
                  </Button>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Hidden>

              <Menu
                id="menu-appbar"
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(userMenuAnchorEl)}
                onClose={handleUserMenuClose}
              >
                <MenuItem component={Link} to="/account">
                  {t('appBar.account')}
                </MenuItem>
                <MenuItem onClick={handleLogout}>{t('appBar.logout')}</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBarx>
  );
}
