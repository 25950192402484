import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useDebounce } from 'use-debounce';
import * as api from '../api';
import type { ApiPlace } from '../api-types';

function usePlacesAC() {
  const [places, setPlaces] = useState<ApiPlace[]>([]);
  const [total, setTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const fetchPlaces = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const data = await api.places({ search: searchTerm });
    setTotal(data.count);
    setPlaces(data.results);
    setLoading(false);
  };

  return {
    places,
    loading,
    placesTotal: total,
    searchTerm,
    setSearchTerm,
  };
}

export const PlacesACContainer = createContainer(usePlacesAC);
