import { useState } from 'react';
import { ConfirmDialog } from '../components/ConfirmDialog';

export const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<{
    title: string;
    content?: string;
    resolve: (v: boolean) => void;
  }>();

  const ask = (title: string, content?: string) => {
    setOpen(true);
    return new Promise((resolve) => {
      // reserve callback to use inside component
      setOptions({ title, content, resolve });
    });
  };

  const handleResponse = (res: boolean) => {
    setOpen(false);
    options?.resolve(res);
  };

  const Dialog = () => (
    <ConfirmDialog
      title={options?.title || ''}
      content={options?.content}
      open={open}
      onAccept={() => handleResponse(true)}
      onReject={() => handleResponse(false)}
    />
  );

  return {
    ask,
    Dialog,
  };
};
