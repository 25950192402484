import { localeLowerCase as low } from 'lower-case';
import { localeUpperCase as up } from 'upper-case';
declare let HIDDEN_FIELDS: string[];

export function getCookie(name: string): string | undefined {
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        const cookieValue = decodeURIComponent(
          cookie.substring(name.length + 1),
        );
        return cookieValue;
      }
    }
  }
}
export const getCsrftoken = () => getCookie('csrftoken');

export const checkEmptyObject = (obj: { [key: string]: unknown }) => {
  for (const i in obj) return false;
  return true;
};

export const checkResponse = (response: Response): boolean => {
  if (response.status >= 200 && response.status < 300) {
    return true;
  }
  return false;
};

export const toTitleCase = (str: string) => {
  const r = str
    ? low(str, 'tr')
        .split(' ')
        .map((s) => (s && s.length > 0 ? toUpperCase(s[0]) + s.slice(1) : ''))
        .join(' ')
    : '';
  return r;
};

export const isDisabledField = (field: string) => HIDDEN_FIELDS.includes(field);

export const toLowerCase = (str: string) => str && low(str, 'tr');
export const toUpperCase = (str: string) => str && up(str, 'tr');
