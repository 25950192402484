import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import * as api from '../api';
import { APIException } from '../common/exception';
import {
  ResetPassConfirmForm,
  ResetPassConfirmFormProps,
} from '../components/ResetPassConfirmForm';

export const ResetPassConfirmPage = () => {
  const { t } = useTranslation();
  const { uid, token } = useParams();
  const [error, setError] = useState<APIException | null>();
  const [done, setDone] = useState(false);

  const handleSubmit: ResetPassConfirmFormProps['onSubmit'] = async (auth) => {
    setError(null);
    try {
      await api.resetPassConfirm({
        uid: uid as string,
        token: token as string,
        new_password1: auth.newPassword,
        new_password2: auth.newPassword,
      });
      setDone(true);
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
      } else setError({ message: 'Unknown error', status: 500 });
    }
  };

  return (
    <Box sx={{ pt: 4, maxWidth: '100%', width: 360, margin: '0 auto' }}>
      <Typography variant="h5">{t('resetPassConfirmPage.title')}</Typography>
      <Paper sx={{ mt: 2, p: 2 }}>
        {done ? (
          <>
            <Alert severity="success" sx={{ mt: 1, mb: 2 }}>
              {t('resetPassConfirmPage.successAlert')}
            </Alert>
            <Button fullWidth variant="contained" color="primary" type="submit">
              {t('resetPassConfirmPage.loginBtn')}
            </Button>
          </>
        ) : (
          <>
            {error && error?.state?.general && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error.state.general}
              </Alert>
            )}
            {error &&
              (error?.state?.form?.fields?.token ||
                error?.state?.form?.fields?.uid) && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {t('resetPassConfirmForm.tokenError')}
                </Alert>
              )}
            <Typography sx={{ mb: 2 }} variant="body1">
              {t('resetPassConfirmPage.message')}
            </Typography>
            <ResetPassConfirmForm
              onSubmit={handleSubmit}
              error={error?.state?.form}
            />
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <Typography color="primary" component={Link} to="/login">
                {t('resetPassConfirmPage.login')}
              </Typography>
            </Stack>
          </>
        )}
      </Paper>
    </Box>
  );
};
