import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiStudentYearInSchool } from '../api-types';

export const StudentYearInSchool: React.FC<{
  children: ApiStudentYearInSchool;
}> = (props) => {
  const { t } = useTranslation();

  switch (props.children) {
    case ApiStudentYearInSchool.PRE_SCHOOL:
      return t('studentYearInSchool.preSchool');
    case ApiStudentYearInSchool.YEAR1:
      return t('studentYearInSchool.year1');
    case ApiStudentYearInSchool.YEAR2:
      return t('studentYearInSchool.year2');
    case ApiStudentYearInSchool.YEAR3:
      return t('studentYearInSchool.year3');
    case ApiStudentYearInSchool.YEAR4:
      return t('studentYearInSchool.year4');
    case ApiStudentYearInSchool.YEAR5:
      return t('studentYearInSchool.year5');
    case ApiStudentYearInSchool.YEAR6:
      return t('studentYearInSchool.year6');
    case ApiStudentYearInSchool.YEAR7:
      return t('studentYearInSchool.year7');
    case ApiStudentYearInSchool.YEAR8:
      return t('studentYearInSchool.year8');
    case ApiStudentYearInSchool.YEAR9:
      return t('studentYearInSchool.year9');
    case ApiStudentYearInSchool.YEAR10:
      return t('studentYearInSchool.year10');
    case ApiStudentYearInSchool.YEAR11:
      return t('studentYearInSchool.year11');
    case ApiStudentYearInSchool.YEAR12:
      return t('studentYearInSchool.year12');
  }
};
