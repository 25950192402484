import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiCountry } from '../api-types';
import * as api from '../api';

type CountryACProps = {
  value?: ApiCountry | null;
  onChange: (value: ApiCountry | null) => void;
  allowCreate?: boolean;
};

export const CountryAC = ({ value, onChange, allowCreate }: CountryACProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ApiCountry[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const fetchOptions = async () => {
    setLoading(true);
    const data = await api.countries({ search: searchTerm });
    let options = data.results;
    if (allowCreate && searchTerm && options?.length === 0) {
      options = [{ title: t('autocomplete.addNew', { searchTerm }), id: -1 }];
    }
    setOptions(options);
    setLoading(false);
  };

  const handleChange = async (v: ApiCountry | string | null) => {
    if (!v || typeof v === 'string') return;
    if (v.id === -1) {
      setLoading(true);
      const data = await api.addCountry({ title: searchTerm });
      setOptions([...options, data]);
      onChange(data);
      setLoading(false);
    } else {
      onChange(v);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={(e, v) => handleChange(v)}
      freeSolo
      disablePortal
      selectOnFocus
      clearOnBlur
      id="city-ac"
      options={options}
      sx={{ width: ['100%', '100%', '100%'] }}
      loading={loading}
      getOptionLabel={(opt) => opt.title}
      renderOption={(props, opt) => (
        <MenuItem {...props} value={opt.id}>
          {opt.title}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('countryAc.label')}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
    />
  );
};
