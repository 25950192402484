import { Button, TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIFormError } from '../common/exception';

export type ResetPassFormProps = {
  onSubmit: (auth: { email: string }) => void;
  error?: APIFormError;
};

export const ResetPassForm = ({ onSubmit, error }: ResetPassFormProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit({ email });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          id="email"
          label={t('resetPassForm.email')}
          variant="outlined"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: '100%' }}
          required
          fullWidth
          error={Boolean(
            (error && error.fields?.email) || (error && error.fields?.username),
          )}
          helperText={
            (error && error.fields?.email) || (error && error.fields?.username)
          }
        />
      </Box>
      <Button fullWidth variant="contained" color="primary" type="submit">
        {t('resetPassForm.submit')}
      </Button>

      {error && error.general && (
        <FormHelperText error={true} sx={{ mt: 2 }}>
          {error.general}
        </FormHelperText>
      )}
    </form>
  );
};
