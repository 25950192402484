import {
  Alert,
  Box,
  Hidden,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { APIException } from '../common/exception';
import type { TeacherFormProps } from '../components/TeacherForm';
import { TeacherForm } from '../components/TeacherForm';
import { TeacherContainer } from '../stores/Teacher';
import { TeacherTypesContainer } from '../stores/TeacherTypes';

export const TeacherEditPage = () => {
  let id: number;
  const { id: idq } = useParams();
  if (idq) id = parseInt(idq, 10);
  const nav = useNavigate();
  const { teacherTypes, teacherTypesLoading } =
    TeacherTypesContainer.useContainer();
  const [error, setError] = useState<APIException>();
  const { t } = useTranslation();
  const { teacher, updateTeacher, teacherLoading } =
    TeacherContainer.useContainer();

  const handleSave: TeacherFormProps['onSubmit'] = async (values) => {
    try {
      if (!teacher) return;
      await updateTeacher(id, {
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
        teacher_user_type: values.teacher_user_type,
        places: values.places.map((p) => p.id),
      });
      nav('/teachers');
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <Box mt={1}>
      <Hidden mdDown>
        <Typography variant="h5">{t('teacherEditPage.title')}</Typography>
      </Hidden>
      {error && error?.state?.general && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error.state.general}
        </Alert>
      )}
      <Paper
        elevation={2}
        sx={{
          mt: 2,
        }}
      >
        {!error && (teacherLoading || teacherTypesLoading) && (
          <LinearProgress />
        )}
        {teacher && (
          <Box p={2}>
            <TeacherForm
              teacherTypes={teacherTypes}
              initialValues={{
                first_name: teacher.first_name,
                last_name: teacher.last_name,
                phone_number: teacher.phone_number,
                teacher_user_type: teacher.teacher_user_type.id,
                places: teacher.places,
              }}
              onSubmit={handleSave}
              onCancel={() => nav(-1)}
              error={error?.state?.form}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};
