export type ApiList<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type ApiPagingParams = {
  page?: number;
  size?: number;
};

export type ApiUser = {
  pk: string;
  username: string;
  first_name: string;
  last_name: string;
  title: string;
  permissions: string[];
  branch: ApiBranch;
};

export type ApiLoginResult = {
  access_token: string;
  refresh_token: string;
  user: ApiUser;
};

export type ApiRefreshTokenResult = {
  access_token: string;
  access_token_expiration: string;
};

export type ApiPlaceType = {
  id: number;
  name: string;
};

export type ApiBranch = {
  id: number;
  name: string;
  code: string;
};

export type ApiCountry = {
  id: number;
  title: string;
};

export type ApiCountryInput = {
  title: string;
};

export type ApiCity = {
  id: number;
  title: string;
  country: ApiCountry;
};

export type ApiCityInput = {
  title: string;
  country: number;
};

export type ApiDistrict = {
  id: number;
  title: string;
  city: ApiCity;
};

export type ApiDistrictInput = {
  title: string;
  city: number;
};

export type ApiNeighborhood = {
  id: number;
  title: string;
  district: ApiDistrict;
};

export type ApiNeighborhoodInput = {
  title: string;
  district: number;
};

export type ApiPlaceInput = {
  name: string;
  place_type: number;
  neighborhood: number | null;
};

export type ApiPlace = {
  id: number;
  name: string;
  branch: ApiBranch;
  place_type: ApiPlaceType;
  neighborhood: ApiNeighborhood | null;
};

export type ApiStudentType = {
  id: number;
  name: string;
};

export type ApiStudentInput = {
  first_name: string;
  last_name: string;
  age?: number;
  school?: string;
  guardian_name?: string;
  guardian_phone_number?: string;
  phone_number?: string;
  student_type: number;
  place: number;
  gender: ApiStudentGender;
  year_in_school?: ApiStudentYearInSchool;
};

export type ApiStudent = {
  id: number;
  first_name: string;
  last_name: string;
  age?: number;
  school?: string;
  guardian_name?: string;
  guardian_phone_number?: string;
  phone_number?: string;
  student_type: ApiStudentType;
  place: ApiPlace;
  gender: ApiStudentGender;
  year_in_school?: ApiStudentYearInSchool;
};

export enum ApiStudentGender {
  MALE = 'M',
  FEMALE = 'F',
}

export enum ApiStudentYearInSchool {
  PRE_SCHOOL = 'PS0',
  YEAR1 = 'Y01',
  YEAR2 = 'Y02',
  YEAR3 = 'Y03',
  YEAR4 = 'Y04',
  YEAR5 = 'Y05',
  YEAR6 = 'Y06',
  YEAR7 = 'Y07',
  YEAR8 = 'Y08',
  YEAR9 = 'Y09',
  YEAR10 = 'Y10',
  YEAR11 = 'Y11',
  YEAR12 = 'Y12',
}

export type ApiTeacherType = {
  id: number;
  name: string;
};

export type ApiTeacherInput = {
  first_name: string;
  last_name: string;
  phone_number?: string | null;
  teacher_user_type: number;
  places: number[];
};

export type ApiAccessCode = {
  id: number;
  code: string;
  updated_at: string;
};

export type ApiTeacher = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  access_code?: ApiAccessCode;
  teacher_user_type: ApiTeacherType;
  places: ApiPlace[];
  is_active: boolean;
};

export type ApiDoc = {
  name: string;
  file: string;
};
