import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ConfirmDialogProps = {
  open: boolean;
  title: string;
  content?: string;
  onAccept: () => void;
  onReject?: () => void;
};

export const ConfirmDialog = ({
  title,
  content,
  onAccept,
  onReject,
  open,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onReject}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ minWidth: 240 }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="secondary" onClick={onReject}>
          {t('confirmDialog.reject')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onAccept}
          autoFocus
        >
          {t('confirmDialog.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
