import { Box, Button, TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIFormError } from '../common/exception';

export type ResetPassConfirmFormProps = {
  onSubmit: (auth: { newPassword: string }) => void;
  error?: APIFormError;
};

export const ResetPassConfirmForm = ({
  onSubmit,
  error,
}: ResetPassConfirmFormProps) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit({ newPassword });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          id="newPassword"
          label={t('resetPassConfirmForm.newPassword')}
          variant="outlined"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ width: '100%' }}
          required
          fullWidth
          error={error && error.fields?.new_password2 !== undefined}
          helperText={error && error.fields?.new_password2}
        />
      </Box>
      <Button fullWidth variant="contained" color="primary" type="submit">
        {t('resetPassConfirmForm.submit')}
      </Button>

      {error && error.general && (
        <FormHelperText error={true} sx={{ mt: 2 }}>
          {error.general}
        </FormHelperText>
      )}
    </form>
  );
};
