import {
  CheckBoxOutlineBlankTwoTone,
  CheckBoxTwoTone,
} from '@mui/icons-material';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApiPlace } from '../api-types';
import { PlacesACContainer } from '../stores/PlacesAC';

type PlacesACMultipleProps = {
  multiple: true;
  value: ApiPlace[] | null;
  onChange: (value: ApiPlace[]) => void;
  disabled?: boolean;
};
type PlacesACSingleProps = {
  multiple: false;
  value: ApiPlace | null;
  onChange: (value: ApiPlace | null) => void;
  disabled?: boolean;
};
type PlacesACProps = PlacesACSingleProps | PlacesACMultipleProps;

const icon = <CheckBoxOutlineBlankTwoTone fontSize="small" />;
const checkedIcon = <CheckBoxTwoTone fontSize="small" />;

const PlacesACLow = (props: PlacesACProps) => {
  const { places, searchTerm, setSearchTerm } =
    PlacesACContainer.useContainer();
  const { t } = useTranslation();

  const onChange = (v: ApiPlace | ApiPlace[] | null) => {
    if (props.multiple) {
      props.onChange((v || []) as ApiPlace[]);
    } else {
      props.onChange(v as ApiPlace);
    }
  };

  return (
    <Autocomplete
      multiple={props.multiple}
      id="places-ac"
      value={props.value}
      options={places}
      inputValue={searchTerm}
      onInputChange={(e, v) => setSearchTerm(v)}
      onChange={(e, v) => onChange(v)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(place) => place.name}
      disableCloseOnSelect
      renderOption={(props, place, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {place.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={t('placesAc.label')} />
      )}
      disabled={props.disabled}
    />
  );
};

export const PlacesAC = (props: PlacesACProps) => (
  <PlacesACContainer.Provider>
    <PlacesACLow {...props} />
  </PlacesACContainer.Provider>
);
