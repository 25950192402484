import { Alert, Box, Hidden, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APIException } from '../common/exception';
import type { PlaceFormProps } from '../components/PlaceForm';
import { PlaceForm } from '../components/PlaceForm';
import { PlacesContainer } from '../stores/Places';
import { PlaceTypesContainer } from '../stores/PlaceTypes';

export const PlaceAddPage = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState<APIException>();
  const { placeTypes } = PlaceTypesContainer.useContainer();
  const { addPlace } = PlacesContainer.useContainer();

  const handleSave: PlaceFormProps['onSubmit'] = async (values) => {
    try {
      await addPlace({
        name: values.name,
        place_type: values.typeId,
        neighborhood: values.neighborhoodId,
      });
      nav('/places');
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <Box mt={1}>
      <Hidden mdDown>
        <Typography variant="h5">{t('placeAddPage.title')}</Typography>
      </Hidden>
      {error && error?.state?.general && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error.state.general}
        </Alert>
      )}
      <Paper
        elevation={2}
        sx={{
          mt: 2,
        }}
      >
        <Box p={2}>
          <PlaceForm
            placeTypes={placeTypes}
            onSubmit={handleSave}
            onCancel={() => nav(-1)}
            error={error?.state?.form}
          />
        </Box>
      </Paper>
    </Box>
  );
};
