import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiStudentGender } from '../api-types';

export const StudentGender: React.FC<{ children: ApiStudentGender }> = (
  props,
) => {
  const { t } = useTranslation();

  return props.children == ApiStudentGender.MALE
    ? t('studentGender.male')
    : t('studentGender.female');
};
