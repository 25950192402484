import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiPlace, ApiPlaceInput } from '../api-types';
import { usePageParams } from '../hooks/usePageParams';

function usePlaces() {
  const [places, setPlaces] = useState<ApiPlace[]>([]);
  const [total, setTotal] = useState(0);
  const { page, setPage, size, setSize } = usePageParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  const fetchPlaces = async () => {
    setLoading(true);
    const data = await api.places({ page, size });
    setTotal(data.count);
    setPlaces(data.results);
    setLoading(false);
  };

  const addPlace = async (data: ApiPlaceInput) => {
    const added = await api.addPlace(data);
    setPlaces([...places, added]);
  };

  const deletePlace = async (id: number) => {
    await api.deletePlace(id);
    setPlaces(places.filter((p) => p.id !== id));
  };

  return {
    places,
    loading,
    total,
    setPlaces,
    addPlace,
    deletePlace,
    page,
    setPage,
    size,
    setSize,
  };
}

export const PlacesContainer = createContainer(usePlaces);
