import {
  Alert,
  Box,
  Hidden,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { APIException } from '../common/exception';
import type { StudentFormProps } from '../components/StudentForm';
import { StudentForm } from '../components/StudentForm';
import { StudentsContainer } from '../stores/Students';
import { StudentTypesContainer } from '../stores/StudentTypes';

export const StudentAddPage = () => {
  const nav = useNavigate();
  const { studentTypes, studentTypesLoading } =
    StudentTypesContainer.useContainer();
  const { addStudent, submitting } = StudentsContainer.useContainer();
  const [error, setError] = useState<APIException>();
  const { t } = useTranslation();

  const handleSave: StudentFormProps['onSubmit'] = async (values) => {
    try {
      await addStudent({
        first_name: values.first_name,
        last_name: values.last_name,
        age: values.age,
        school: values.school,
        guardian_name: values.guardian_name,
        guardian_phone_number: values.guardian_phone_number,
        phone_number: values.phone_number,
        student_type: values.student_type?.id,
        place: values.place.id,
        gender: values.gender,
        year_in_school: values.year_in_school,
      });
      nav('/students');
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <Box mt={1}>
      <Hidden mdDown>
        <Typography variant="h5">{t('studentEditPage.title')}</Typography>
      </Hidden>
      {error && error?.state?.general && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {error.state.general}
        </Alert>
      )}
      <Paper
        elevation={2}
        sx={{
          boxShadow: [0, 1],
          mt: 2,
        }}
      >
        {!error && (studentTypesLoading || submitting) && <LinearProgress />}
        <Box p={2}>
          <StudentForm
            studentTypes={studentTypes}
            onSubmit={handleSave}
            onCancel={() => nav(-1)}
            error={error?.state?.form}
            disabled={submitting}
          />
        </Box>
      </Paper>
    </Box>
  );
};
