import { useSearchParams } from 'react-router-dom';

export const usePageParams = () => {
  const [query, setQuery] = useSearchParams();
  const page = parseInt(query.get('page') || '1');
  const size = parseInt(query.get('size') || String(window.DEFAULT_PAGE_SIZE));

  const setPage = (page: number) => {
    const q = new URLSearchParams(query);
    q.set('page', String(page));
    setQuery(q);
  };

  const setSize = (size: number) => {
    const q = new URLSearchParams(query);
    q.set('size', String(size));
    setQuery(q);
  };

  return {
    page,
    setPage,
    size,
    setSize,
  };
};
