/* eslint-disable @typescript-eslint/no-unused-vars */
import { checkEmptyObject } from './utils';

export interface ErrorResponse {
  [key: string]: string | string[];
}

const ERROR_GENERAL_KEY = 'detail';
const ERROR_NONFIELD_KEY = 'non_field_errors';

export class APIFormError {
  general?: string[];
  fields?: ErrorResponse;

  constructor(errors: ErrorResponse) {
    this.general = errors[ERROR_NONFIELD_KEY] as string[];
    const { [ERROR_NONFIELD_KEY]: string, ...remainingErrors } = errors;

    if (!checkEmptyObject(remainingErrors)) {
      this.fields = remainingErrors;
    }
  }

  get message() {
    if (this.general) {
      return this.general.join(', ');
    } else if (this.fields) {
      const fieldErrors: string[] = [];
      for (const [field, values] of Object.entries(
        this.fields as { [key: string]: string[] },
      )) {
        const fieldErrorDetail: string = values.join(', ');
        fieldErrors.push(`${field}: ${fieldErrorDetail}`);
      }
      return `${fieldErrors.join('; ')}`;
    }
    return '';
  }
}

export class APIErrorState {
  general?: string;
  form?: APIFormError;

  constructor(errors: ErrorResponse) {
    this.general = errors[ERROR_GENERAL_KEY] as string;

    const { [ERROR_GENERAL_KEY]: string, ...remainingErrors } = errors;

    if (!checkEmptyObject(remainingErrors)) {
      const apiFormError = new APIFormError(remainingErrors);
      this.form = apiFormError;
    }
  }

  get message() {
    if (this.general) {
      return this.general;
    } else if (this.form) {
      return this.form.message;
    }
  }
}

export class APIException {
  status: number;
  state?: APIErrorState;

  constructor(status: number, response?: ErrorResponse) {
    this.status = status;
    if (response) this.state = new APIErrorState(response);
  }

  get message() {
    if (this.state) {
      return `${this.state?.message}`;
    }
    return `Error ${this.status}`;
  }
}
