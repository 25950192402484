import {
  Container,
  Grid,
  Hidden,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { AppBar } from './components/AppBar';
import { BottomNav } from './components/BottomNav';
import { NavList } from './components/NavList';
import { AuthContainer } from './stores/Auth';
import { PlaceContainer } from './stores/Place';
import { PlacesContainer } from './stores/Places';
import { PlaceTypesContainer } from './stores/PlaceTypes';
import { StudentContainer } from './stores/Student';
import { StudentsContainer } from './stores/Students';
import { StudentTypesContainer } from './stores/StudentTypes';
import { TeacherContainer } from './stores/Teacher';
import { TeachersContainer } from './stores/Teachers';
import { TeacherTypesContainer } from './stores/TeacherTypes';
import { themes } from './theme';
import Account from './views/Account';
import { DocsPage } from './views/Docs';
import { LoginPage } from './views/Login';
import { PlaceAddPage } from './views/PlaceAdd';
import { PlaceDetailPage } from './views/PlaceDetail';
import { PlaceEditPage } from './views/PlaceEdit';
import { PlacesPage } from './views/Places';
import { PrintCertPage } from './views/PrintCert';
import { ResetPassPage } from './views/ResetPass';
import { ResetPassConfirmPage } from './views/ResetPassConfirm';
import { StudentAddPage } from './views/StudentAdd';
import { StudentDetailPage } from './views/StudentDetail';
import { StudentEditPage } from './views/StudentEdit';
import { StudentPrintCertPage } from './views/StudentPrintCert';
import { StudentsPage } from './views/Students';
import { TeacherAddPage } from './views/TeacherAdd';
import { TeacherDetailPage } from './views/TeacherDetail';
import { TeacherEditPage } from './views/TeacherEdit';
import { TeachersPage } from './views/Teachers';
import { DocsContainer } from './stores/Docs';

declare let APP_THEME: string;
const theme = themes[parseInt(APP_THEME)];

const PlacesRoot = () => (
  <PlacesContainer.Provider>
    <PlaceTypesContainer.Provider>
      <Outlet />
    </PlaceTypesContainer.Provider>
  </PlacesContainer.Provider>
);

const PlaceDetailRoot = () => {
  const { id: idq } = useParams();
  const id = idq ? parseInt(idq) : 1;
  return (
    <PlaceContainer.Provider initialState={{ id }}>
      <Outlet />
    </PlaceContainer.Provider>
  );
};
const TeachersRoot = () => (
  <TeachersContainer.Provider>
    <TeacherTypesContainer.Provider>
      <Outlet />
    </TeacherTypesContainer.Provider>
  </TeachersContainer.Provider>
);

const TeacherDetailRoot = () => {
  const { id: idq } = useParams();
  const id = idq ? parseInt(idq) : 1;
  return (
    <TeacherContainer.Provider initialState={{ id }}>
      <Outlet />
    </TeacherContainer.Provider>
  );
};

const StudentsRoot = () => (
  <StudentsContainer.Provider>
    <StudentTypesContainer.Provider>
      <Outlet />
    </StudentTypesContainer.Provider>
  </StudentsContainer.Provider>
);

const StudentDetailRoot = () => {
  const { id: idq } = useParams();
  const id = idq ? parseInt(idq) : 1;
  return (
    <StudentContainer.Provider initialState={{ id }}>
      <Outlet />
    </StudentContainer.Provider>
  );
};

const DocsRoot = () => (
  <DocsContainer.Provider>
    <Outlet />
  </DocsContainer.Provider>
);

function PublicRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/reset-pass" element={<ResetPassPage />} />
      <Route
        path="/reset-pass/confirm/:uid/:token"
        element={<ResetPassConfirmPage />}
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

function PrivateRoutes() {
  return (
    <Grid container spacing={2}>
      <Hidden mdDown>
        <Grid item md={3} lg={3} sx={{ mt: 1 }}>
          <NavList />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9} lg={9}>
        <Routes>
          <Route path="/places" element={<PlacesRoot />}>
            <Route path="" element={<PlacesPage />} />
            <Route path="new" element={<PlaceAddPage />} />
            <Route path=":id" element={<PlaceDetailRoot />}>
              <Route path="detail" element={<PlaceDetailPage />} />
              <Route path="edit" element={<PlaceEditPage />} />
            </Route>
          </Route>

          <Route path="/teachers" element={<TeachersRoot />}>
            <Route path="" element={<TeachersPage />} />
            <Route path="new" element={<TeacherAddPage />} />
            <Route path=":id" element={<TeacherDetailRoot />}>
              <Route path="detail" element={<TeacherDetailPage />} />
              <Route path="edit" element={<TeacherEditPage />} />
            </Route>
          </Route>

          <Route path="/students" element={<StudentsRoot />}>
            <Route path="" element={<StudentsPage />} />
            <Route path="new" element={<StudentAddPage />} />
            <Route path=":id" element={<StudentDetailRoot />}>
              <Route path="detail" element={<StudentDetailPage />} />
              <Route path="edit" element={<StudentEditPage />} />
            </Route>
          </Route>

          <Route path="/cert" element={<StudentsRoot />}>
            <Route path="" element={<PrintCertPage />} />
            <Route path="students/:id" element={<StudentPrintCertPage />} />
          </Route>

          <Route path="/docs" element={<DocsRoot />}>
            <Route path="" element={<DocsPage />} />
          </Route>

          <Route path="/account" element={<Account />} />
          <Route path="/" element={<Navigate to="/places" replace />} />
          <Route path="/login" element={<Navigate to="/places" replace />} />
        </Routes>
      </Grid>
    </Grid>
  );
}

function AppWithProviders() {
  const theme = useTheme();
  const navHeight = `${theme.mixins.toolbar.minHeight}px`;
  const { user, logout, isAuthCheck } = AuthContainer.useContainer();

  if (!isAuthCheck) {
    return (
      <Container
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PulseLoader
          color={theme.palette.primary.main}
          loading={true}
        ></PulseLoader>
      </Container>
    );
  }

  return (
    <Router>
      {user && <AppBar user={user} onLogout={logout} />}
      <Container
        maxWidth="lg"
        sx={{
          paddingBottom: [navHeight, navHeight, 0],
          paddingLeft: [0, 2, 3],
          paddingRight: [0, 2, 3],
        }}
      >
        {user ? <PrivateRoutes /> : <PublicRoutes />}
      </Container>
      {user && (
        <Hidden mdUp>
          <BottomNav />
        </Hidden>
      )}
    </Router>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthContainer.Provider>
        <AppWithProviders />
      </AuthContainer.Provider>
    </ThemeProvider>
  );
}

export default App;
