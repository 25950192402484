import { AddTwoTone as AddIcon, SearchTwoTone } from '@mui/icons-material';
import {
  Box,
  Chip,
  Fab,
  LinearProgress,
  List,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../common/utils';
import { ListItem } from '../components/ListItem';
import { usePageParams } from '../hooks/usePageParams';
import { AuthContainer } from '../stores/Auth';
import { StudentsContainer } from '../stores/Students';
import { ApiStudent } from '../api-types';

export const StudentsPage = () => {
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();
  const { page, setPage, size, setSize } = usePageParams();

  const {
    setStudentsPage,
    setStudentsSize,
    students,
    studentsTotal,
    studentsLoading,
  } = StudentsContainer.useContainer();

  useEffect(() => {
    setStudentsPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    setStudentsSize(size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const getSecondaryText = (s: ApiStudent) => {
    let t = toTitleCase(s.place.name);
    if (s.school) {
      t += ` - ${toTitleCase(s.school)}`;
    }
    return t;
  };

  return (
    <Box mt={1}>
      <Paper
        elevation={2}
        sx={{
          mb: [8, 8, 'unset'],
          position: [null, null, 'relative'],
          mt: [0, 2, 4],
        }}
      >
        {studentsLoading && <LinearProgress />}
        {can('add_student') && (
          <Fab
            component={Link}
            to="/students/new"
            color="secondary"
            sx={{
              position: ['fixed', 'fixed', 'absolute'],
              top: [null, null, -28],
              bottom: [70, null, null],
              right: 28,
            }}
          >
            <AddIcon />
          </Fab>
        )}
        {/* <Autocomplete
            sx={{ my: 2 }}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={students.map((s) => s.first_name + ' ' + s.last_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Arama"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          /> */}
        <List>
          {!studentsLoading && students?.length === 0 && (
            <ListItem
              icon={<SearchTwoTone />}
              primaryText={
                <Typography>{t('studentsPage.noRecords')}</Typography>
              }
            />
          )}
          {students.map((s) => (
            <ListItem
              key={s.id}
              primaryText={
                <>
                  {`${toTitleCase(s.first_name)} ${toTitleCase(s.last_name)}`}{' '}
                  <Chip
                    component="span"
                    color="secondary"
                    variant="outlined"
                    label={s.student_type.name}
                    size="small"
                  />
                </>
              }
              secondaryText={getSecondaryText(s)}
              linkTo={`/students/${s.id}/detail`}
            />
          ))}
        </List>
        <TablePagination
          component="div"
          count={studentsTotal}
          page={page - 1}
          onPageChange={(e, v) => setPage(v + 1)}
          rowsPerPage={size}
          rowsPerPageOptions={window.PAGINATION_OPTS}
          onRowsPerPageChange={(e) => setSize(parseInt(e.target.value))}
          labelRowsPerPage={t('pagination.sizeLabel')}
          labelDisplayedRows={({ from, to, count }) =>
            t('pagination.displayLabel', { from, to, count })
          }
        />
      </Paper>
    </Box>
  );
};
