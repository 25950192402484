import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiDistrict, ApiNeighborhood } from '../api-types';
import * as api from '../api';

type NeighborhoodACProps = {
  district: ApiDistrict;
  value?: ApiNeighborhood | null;
  onChange: (value: ApiNeighborhood | null) => void;
  allowCreate?: boolean;
};

export const NeighborhoodAC = ({
  district,
  value,
  onChange,
  allowCreate,
}: NeighborhoodACProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<ApiNeighborhood[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district, searchTerm]);

  const fetchOptions = async () => {
    setLoading(true);
    const data = await api.neighborhoods({
      district: String(district.id),
      search: searchTerm,
    });
    let options = data.results;
    if (allowCreate && searchTerm && options?.length === 0) {
      options = [
        { title: t('autocomplete.addNew', { searchTerm }), id: -1, district },
      ];
    }
    setOptions(options);
    setLoading(false);
  };

  const handleChange = async (v: ApiNeighborhood | string | null) => {
    if (!v || typeof v === 'string') return;
    if (v.id === -1) {
      setLoading(true);
      const data = await api.addNeighborhood({
        title: searchTerm,
        district: district.id,
      });
      setOptions([...options, data]);
      onChange(data);
      setLoading(false);
    } else {
      onChange(v);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={(e, v) => handleChange(v)}
      freeSolo
      disablePortal
      selectOnFocus
      clearOnBlur
      id="neighborhood-ac"
      options={options}
      sx={{ width: ['100%', '100%', '100%'] }}
      loading={loading}
      getOptionLabel={(opt) => opt.title}
      renderOption={(props, opt) => (
        <MenuItem {...props} value={opt.id}>
          {opt.title}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('neighborhoodAc.label')}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
    />
  );
};
