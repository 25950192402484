import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiPlace, ApiTeacherType } from '../api-types';
import { APIFormError } from '../common/exception';
import { PhoneInput } from './PhoneInput';
import { PlacesAC } from './PlacesAC';
declare let PHONE_HIDDEN_USER_TYPE_IDS: string[];

export type TeacherFormProps = {
  initialValues?: {
    first_name: string;
    last_name: string;
    phone_number: string;
    teacher_user_type: number;
    places: ApiPlace[];
  };
  teacherTypes: ApiTeacherType[];
  onSubmit: (values: {
    first_name: string;
    last_name: string;
    phone_number: string | null;
    teacher_user_type: number;
    places: ApiPlace[];
  }) => void;
  onCancel: () => void;
  error?: APIFormError;
  disabled?: boolean;
};

export const TeacherForm = ({
  initialValues,
  teacherTypes,
  onSubmit,
  onCancel,
  error,
  disabled,
}: TeacherFormProps) => {
  const { t } = useTranslation();
  const [first_name, setFirstName] = useState(initialValues?.first_name || '');
  const [last_name, setLastName] = useState(initialValues?.last_name || '');
  const [phone_number, setPhoneNumber] = useState(
    initialValues?.phone_number || null,
  );
  const [teacher_user_type, setTeacherType] = useState<number | null>(
    initialValues?.teacher_user_type || null,
  );
  const [places, setTeacherPlaces] = useState<ApiPlace[] | null>(
    initialValues?.places || null,
  );
  const [phoneHidden, setPhoneHidden] = useState(true);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const phoneHidden =
      !teacher_user_type ||
      PHONE_HIDDEN_USER_TYPE_IDS.includes(String(teacher_user_type));
    setPhoneHidden(phoneHidden);
    if (phoneHidden) {
      setPhoneNumber(null);
    }
  }, [teacher_user_type]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!teacher_user_type || !places) return;
    onSubmit({
      first_name,
      last_name,
      phone_number,
      teacher_user_type,
      places,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <FormControl sx={{ width: ['100%', '100%', '100%'] }}>
          <InputLabel required id="type">
            {t('teacherForm.teacherType')}
          </InputLabel>
          <Select
            labelId="type"
            id="type-select"
            value={teacher_user_type}
            label={t('teacherForm.teacherType')}
            onChange={(e) => {
              if (e.target.value) {
                setTeacherType(parseInt(String(e.target.value)));
              }
            }}
            disabled={disabled}
            required
          >
            {teacherTypes.map((st) => (
              <MenuItem key={st.id} value={st.id}>
                {st.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          id="firstName"
          label={t('teacherForm.firstName')}
          variant="outlined"
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          sx={{ width: ['100%', '100%', '100%'] }}
          required
          error={error && error.fields?.first_name !== undefined}
          helperText={error && error.fields?.first_name}
          disabled={disabled}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          id="lastName"
          label={t('teacherForm.lastName')}
          variant="outlined"
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          sx={{ width: ['100%', '100%', '100%'] }}
          required
          error={error && error.fields?.last_name !== undefined}
          helperText={error && error.fields?.last_name}
          disabled={disabled}
        />
      </Box>

      {!phoneHidden && (
        <Box sx={{ mb: 2 }}>
          <PhoneInput
            id="phoneNumber"
            label={t('teacherForm.phoneNumber')}
            variant="outlined"
            initialValue={phone_number}
            onChange={setPhoneNumber}
            sx={{ width: ['100%', '100%', '100%'] }}
            error={error && error.fields?.phone_number !== undefined}
            helperText={error && error.fields?.phone_number}
            disabled={disabled}
          />
        </Box>
      )}

      <Box sx={{ mb: 2 }}>
        <FormControl sx={{ width: ['100%', '100%', '100%'] }}>
          <PlacesAC
            multiple={true}
            value={places || []}
            onChange={(pl) => setTeacherPlaces(pl)}
          />
        </FormControl>
      </Box>

      <Stack direction={mdUp ? 'row' : 'column'} spacing={1}>
        <Button
          color="secondary"
          type="button"
          onClick={onCancel}
          disabled={disabled}
        >
          {t('form.cancel')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={disabled}
        >
          {t('form.save')}
        </Button>
      </Stack>
      {error && error.general && (
        <FormHelperText error={true} sx={{ mt: 2 }}>
          {error.general}
        </FormHelperText>
      )}
    </form>
  );
};
