import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiPlace, ApiPlaceInput } from '../api-types';
import { PlacesContainer } from './Places';

function usePlace(initialState?: { id: number }) {
  const { places, setPlaces } = PlacesContainer.useContainer();
  const [place, setPlace] = useState<ApiPlace | null>(null);
  const [placeLoading, setPlaceLoading] = useState(false);

  useEffect(() => {
    if (initialState?.id) fetchPlace(initialState?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.id]);

  const fetchPlace = async (id: number) => {
    setPlaceLoading(true);
    const data = await api.place(id);
    setPlace(data);
    setPlaceLoading(false);
  };

  const updatePlace = async (id: number, data: ApiPlaceInput) => {
    setPlaceLoading(true);
    const updated = await api.updatePlace(id, data);
    const newPlaces = places.map((p) => (p.id === id ? updated : p));
    if (place?.id === updated.id) setPlace(updated);
    setPlaces(newPlaces);
    setPlaceLoading(false);
  };

  return {
    place,
    placeLoading,
    fetchPlace,
    updatePlace,
  };
}

export const PlaceContainer = createContainer(usePlace);
