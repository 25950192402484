import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApiPlace,
  ApiStudentGender,
  ApiStudentType,
  ApiStudentYearInSchool,
} from '../api-types';
import { APIFormError } from '../common/exception';
import { isDisabledField } from '../common/utils';
import { PhoneInput } from './PhoneInput';
import { PlacesAC } from './PlacesAC';
import { StudentGender } from './StudentGender';
import { StudentYearInSchool } from './StudentYearInSchool';

export type StudentFormProps = {
  initialValues?: {
    first_name: string;
    last_name: string;
    age?: number;
    school?: string;
    guardian_name?: string;
    guardian_phone_number?: string;
    phone_number?: string;
    student_type: ApiStudentType;
    place: ApiPlace;
    gender: ApiStudentGender;
    year_in_school?: ApiStudentYearInSchool;
  };
  studentTypes: ApiStudentType[];
  onSubmit: (values: {
    first_name: string;
    last_name: string;
    age?: number;
    school?: string;
    guardian_name?: string;
    guardian_phone_number?: string;
    phone_number?: string;
    student_type: ApiStudentType;
    place: ApiPlace;
    gender: ApiStudentGender;
    year_in_school?: ApiStudentYearInSchool;
  }) => void;
  onCancel: () => void;
  error?: APIFormError;
  disabled?: boolean;
};

export const StudentForm = ({
  initialValues,
  studentTypes,
  onSubmit,
  onCancel,
  error,
  disabled,
}: StudentFormProps) => {
  const { t } = useTranslation();
  const [first_name, setFirstName] = useState(initialValues?.first_name || '');
  const [last_name, setLastName] = useState(initialValues?.last_name || '');
  const [age, setAge] = useState(initialValues?.age);
  const [school, setSchool] = useState(initialValues?.school);
  const [guardian_name, setGuardianName] = useState(
    initialValues?.guardian_name,
  );
  const [phone_number, setPhoneNumber] = useState(initialValues?.phone_number);
  const [guardian_phone_number, setGuardianPhoneNumber] = useState(
    initialValues?.guardian_phone_number,
  );
  const [student_type, setStudentType] = useState(
    initialValues?.student_type || null,
  );
  const [place, setStudentPlace] = useState<ApiPlace | null>(
    initialValues?.place || null,
  );
  const [gender, setStudentGender] = useState(initialValues?.gender);
  const [year_in_school, setYearInSchool] = useState(
    initialValues?.year_in_school,
  );

  const [phoneHidden, setPhoneHidden] = useState(true);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    // hide phone number input for female students
    const phoneHidden = gender !== ApiStudentGender.MALE;
    setPhoneHidden(phoneHidden);
    if (phoneHidden) {
      setPhoneNumber('');
    }
  }, [gender]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!first_name || !last_name || !student_type || !place || !gender) {
      return;
    }
    onSubmit({
      first_name,
      last_name,
      student_type,
      place,
      age,
      school,
      guardian_name,
      guardian_phone_number,
      phone_number,
      gender,
      year_in_school,
    });
  };

  const yearInSchoolOpts = [
    ApiStudentYearInSchool.PRE_SCHOOL,
    ApiStudentYearInSchool.YEAR1,
    ApiStudentYearInSchool.YEAR2,
    ApiStudentYearInSchool.YEAR3,
    ApiStudentYearInSchool.YEAR4,
    ApiStudentYearInSchool.YEAR5,
    ApiStudentYearInSchool.YEAR6,
    ApiStudentYearInSchool.YEAR7,
    ApiStudentYearInSchool.YEAR8,
    ApiStudentYearInSchool.YEAR9,
    ApiStudentYearInSchool.YEAR10,
    ApiStudentYearInSchool.YEAR11,
    ApiStudentYearInSchool.YEAR12,
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          id="firstName"
          label={t('studentForm.firstName')}
          variant="outlined"
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          sx={{ width: ['100%', '100%', 'unset'] }}
          required
          error={error && error.fields?.first_name !== undefined}
          helperText={error && error.fields?.first_name}
          disabled={disabled}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          id="lastName"
          label={t('studentForm.lastName')}
          variant="outlined"
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          sx={{ width: ['100%', '100%', 'unset'] }}
          required
          error={error && error.fields?.last_name !== undefined}
          helperText={error && error.fields?.last_name}
          disabled={disabled}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <FormControl
          sx={{ width: ['100%', '100%', '230px'] }}
          disabled={disabled}
        >
          <InputLabel required id="type">
            {t('studentForm.studentType')}
          </InputLabel>
          <Select
            labelId="type"
            id="type-select"
            value={student_type?.id}
            label={t('studentForm.studentType')}
            onChange={(e) => {
              const v = studentTypes.find((st) => st.id === e.target.value);
              v && setStudentType(v);
            }}
            required
            disabled={disabled}
          >
            {studentTypes.map((st) => (
              <MenuItem key={st.id} value={st.id}>
                {st.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ mb: 2 }}>
        <FormControl variant="outlined" required disabled={disabled}>
          <FormLabel required id="gender-label">
            {t('studentForm.gender')}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender-label"
            name="gender"
            value={gender}
            onChange={(e, v) => v && setStudentGender(v as ApiStudentGender)}
          >
            <FormControlLabel
              disabled={disabled}
              value={ApiStudentGender.MALE}
              control={<Radio required />}
              label={<StudentGender>{ApiStudentGender.MALE}</StudentGender>}
            />
            <FormControlLabel
              disabled={disabled}
              value={ApiStudentGender.FEMALE}
              control={<Radio required />}
              label={<StudentGender>{ApiStudentGender.FEMALE}</StudentGender>}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {!isDisabledField('year_in_school') && (
        <Box sx={{ mb: 2 }}>
          <FormControl sx={{ width: ['100%', '100%', '230px'] }}>
            <InputLabel id="year-in-school-label">
              {t('studentForm.yearInSchool')}
            </InputLabel>
            <Select
              labelId="year-in-school-label"
              name="year_in_school"
              value={year_in_school}
              label={t('studentForm.yearInSchool')}
              onChange={(e) => {
                setYearInSchool(e.target.value as ApiStudentYearInSchool);
              }}
            >
              <MenuItem value="">
                {t('studentForm.yearInSchoolEmptyOption')}
              </MenuItem>
              {yearInSchoolOpts.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  <StudentYearInSchool>{opt}</StudentYearInSchool>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {t('studentForm.yearInSchoolHelperText')}
            </FormHelperText>
          </FormControl>
        </Box>
      )}

      <Box sx={{ mb: 2 }}>
        <PlacesAC
          multiple={false}
          value={place}
          onChange={setStudentPlace}
          disabled={disabled}
        />
      </Box>

      {!isDisabledField('age') && (
        <Box sx={{ mb: 2 }}>
          <TextField
            id="age"
            type="number"
            label={t('studentForm.age')}
            variant="outlined"
            value={age}
            onChange={(e) =>
              e.target.value && setAge(parseInt(String(e.target.value)))
            }
            sx={{ width: ['100%', '100%', 'unset'] }}
            required
            error={error && error.fields?.age !== undefined}
            helperText={error && error.fields?.age}
          />
        </Box>
      )}

      {!isDisabledField('school') && (
        <Box sx={{ mb: 2 }}>
          <TextField
            id="school"
            label={t('studentForm.school')}
            variant="outlined"
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            sx={{ width: ['100%', '100%', 'unset'] }}
            error={error && error.fields?.school !== undefined}
            helperText={error && error.fields?.school}
          />
        </Box>
      )}

      {!isDisabledField('guardian_name') && (
        <Box sx={{ mb: 2 }}>
          <TextField
            id="guardianName"
            label={t('studentForm.guardianName')}
            variant="outlined"
            value={guardian_name}
            onChange={(e) => setGuardianName(e.target.value)}
            sx={{ width: ['100%', '100%', 'unset'] }}
            error={error && error.fields?.guardian_name !== undefined}
            helperText={error && error.fields?.guardian_name}
            required
          />
        </Box>
      )}

      {!isDisabledField('guardian_phone_number') && (
        <Box sx={{ mb: 2 }}>
          <PhoneInput
            id="guardianPhoneNumber"
            label={t('studentForm.guardianPhoneNumber')}
            initialValue={guardian_phone_number || null}
            onChange={(v) => v && setGuardianPhoneNumber(v)}
            variant="outlined"
            sx={{ width: ['100%', '100%', 'unset'] }}
            error={error && error.fields?.guardian_phone_number !== undefined}
            helperText={error && error.fields?.guardian_phone_number}
            required
          />
        </Box>
      )}

      {!isDisabledField('phone_number') && !phoneHidden && (
        <Box sx={{ mb: 2 }}>
          <PhoneInput
            id="phoneNumber"
            label={t('studentForm.phoneNumber')}
            initialValue={phone_number || null}
            onChange={(v) => v && setPhoneNumber(v)}
            variant="outlined"
            sx={{ width: ['100%', '100%', 'unset'] }}
            error={error && error.fields?.phone_number !== undefined}
            helperText={error && error.fields?.phone_number}
            required
          />
        </Box>
      )}

      <Stack direction={mdUp ? 'row' : 'column'} spacing={1}>
        <Button
          color="secondary"
          type="button"
          onClick={onCancel}
          disabled={disabled}
        >
          {t('form.cancel')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={disabled}
        >
          {t('form.save')}
        </Button>
      </Stack>
      {error && error.general && (
        <FormHelperText error={true} sx={{ mt: 2 }}>
          {error.general}
        </FormHelperText>
      )}
    </form>
  );
};
