import { AddTwoTone as AddIcon, SearchTwoTone } from '@mui/icons-material';
import {
  Box,
  Chip,
  Fab,
  LinearProgress,
  List,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../common/utils';
import { ListItem } from '../components/ListItem';
import { AuthContainer } from '../stores/Auth';
import { PlacesContainer } from '../stores/Places';

export const PlacesPage = () => {
  const { can } = AuthContainer.useContainer();
  const { t } = useTranslation();

  const { page, setPage, size, setSize, places, total, loading } =
    PlacesContainer.useContainer();

  return (
    <Box mt={1}>
      <Paper
        elevation={2}
        sx={{
          mb: [8, 8, 'unset'],
          position: [null, null, 'relative'],
          mt: [0, 2, 4],
        }}
      >
        {loading && <LinearProgress />}
        {can('add_place') && (
          <Fab
            component={Link}
            to="/places/new"
            color="secondary"
            sx={{
              position: ['fixed', 'fixed', 'absolute'],
              top: [null, null, -28],
              bottom: [70, null, null],
              right: 28,
            }}
          >
            <AddIcon />
          </Fab>
        )}
        <List>
          {!loading && places?.length === 0 && (
            <ListItem
              icon={<SearchTwoTone />}
              primaryText={<Typography>{t('placesPage.noRecords')}</Typography>}
            />
          )}
          {places.map((p) => (
            <ListItem
              key={p.id}
              primaryText={
                <>
                  {p.name}{' '}
                  <Chip
                    component="span"
                    color="secondary"
                    variant="outlined"
                    label={p.place_type.name}
                    size="small"
                  />
                </>
              }
              secondaryText={
                <>
                  {toTitleCase(
                    `${p.neighborhood?.title}, ${p.neighborhood?.district?.title}`,
                  )}
                </>
              }
              linkTo={`/places/${p.id}/detail`}
            />
          ))}
        </List>
        <TablePagination
          component="div"
          count={total}
          page={page - 1}
          onPageChange={(e, v) => setPage(v + 1)}
          rowsPerPage={size}
          rowsPerPageOptions={window.PAGINATION_OPTS}
          onRowsPerPageChange={(e) => setSize(parseInt(e.target.value))}
          labelRowsPerPage={t('pagination.sizeLabel')}
          labelDisplayedRows={({ from, to, count }) =>
            t('pagination.displayLabel', { from, to, count })
          }
        />
      </Paper>
    </Box>
  );
};
