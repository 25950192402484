import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

i18n.use(initReactI18next).init({
  resources: {
    tr: {
      translation: {
        'global.new': 'Yeni',

        'appBar.logout': 'Çıkış',
        'appBar.login': 'Giriş',
        'appBar.profile': 'Hesap',
        'appBar.places': 'Mahaller',
        'appBar.students': 'Talebeler',
        'appBar.teachers': 'Okutucular',
        'appBar.placeDetail': 'Mahal Detayı',
        'appBar.placeEdit': 'Mahal Düzenle',
        'appBar.placeNew': 'Yeni Mahal',
        'appBar.studentDetail': 'Talebe Detayı',
        'appBar.studentEdit': 'Talebe Düzenle',
        'appBar.studentNew': 'Yeni Talebe',
        'appBar.studentCert': "Kur'an-ı Kerime Geçme Belgesi",
        'appBar.teacherDetail': 'Okutucu Detayı',
        'appBar.teacherEdit': 'Okutucu Düzenle',
        'appBar.teacherNew': 'Yeni Okutucu',
        'appBar.account': 'Hesabım',
        'appBar.docs': 'Dokümanlar',

        'account.username': 'Kullanıcı Adı',
        'account.full_name': 'Adı Soyadı',
        'account.branch': 'Şube',

        'autocomplete.addNew': 'Oluştur: {{searchTerm}}',

        'cityAc.label': 'Şehir / Eyalet',
        'countryAc.label': 'Ülke',
        'confirmDialog.accept': 'Devam Et',
        'confirmDialog.reject': 'İptal',

        'districtAc.label': 'İlçe / Şehir',

        'docsPage.noDocs': 'Doküman bulunamadı.',

        'form.cancel': 'İptal',
        'form.save': 'Kaydet',
        'loginForm.emailOrUsername': 'E-posta / Kullanıcı Adı',
        'loginForm.password': 'Şifre',
        'loginForm.submit': 'Giriş',
        'loginPage.title': 'Giriş',
        'loginPage.resetPass': 'Şifrenizi unuttunuz mu?',

        'navList.places': 'Mahaller',
        'navList.students': 'Talebeler',
        'navList.teachers': 'Okutucular',
        'navList.account': 'Hesabım',
        'navList.printCert': "Kur'an-ı Kerime Geçme Belgesi",
        'navList.docs': 'Dokümanlar',
        'navListMobile.printCert': "Kur'an-ı Kerime...",
        'neighborhoodAc.label': 'Mahalle / Sokak',
        'pagination.displayLabel': '{{from}}-{{to}} / {{count}}',
        'pagination.sizeLabel': 'Göster:',

        'phoneInput.invalid': 'Geçersiz iletişim numarası',

        'placeAddPage.title': 'Yeni Mahal',
        'placeEditPage.title': 'Mahal Düzenle',
        'placeForm.name': 'Mahal İsmi',
        'placeForm.type': 'Mahal Türü',

        'placesAc.label': 'Mahal',

        'placesPage.deletePlace': 'Mahal Silinecek',
        'placesPage.deletePlaceContent': 'Bu işlem geri alınamaz.',
        'placesPage.deletePlaceTitle': 'Sil',
        'placesPage.title': 'Mahaller',
        'placesPage.noRecords': 'Henüz kayıt oluşturulmadı',

        'placeDetailPage.title': 'Mahal Detayları',
        'placeDetailPage.deletePlace': 'Mahal Silinecek',
        'placeDetailPage.deletePlaceContent': 'Bu işlem geri alınamaz.',
        'placeDetailPage.deletePlaceTitle': 'Sil',
        'placeDetailPage.name': 'İsmi',
        'placeDetailPage.type': 'Türü',
        'placeDetailPage.branch': 'Şubesi',
        'placeDetailPage.neighborhood': 'Mahalle / Sokak',
        'placeDetailPage.city': 'Şehir / Eyalet',
        'placeDetailPage.district': 'İlçe / Şehir',
        'placeDetailPage.return': 'Geri Dön',
        'placeDetailPage.edit': 'Düzenle',

        'resetPassConfirmForm.submit': 'Gönder',
        'resetPassConfirmForm.newPassword': 'Yeni Şifre',
        'resetPassConfirmPage.title': 'Şifre Sıfırla',
        'resetPassConfirmPage.message':
          'Bu aşamada yeni şifrenizi belirleyebilirsiniz.',
        'resetPassConfirmPage.login': 'Bunun yerine giriş yapın',
        'resetPassConfirmPage.loginBtn': 'Giriş Yap',
        'resetPassConfirmPage.successAlert':
          'Şifreniz sıfırlandı, şimdi giriş yapabilirsiniz.',
        'resetPassConfirmForm.tokenError':
          'Şifre sıfırlama bağlantınız geçersiz veya bağlantının süresi dolmuş.',

        'resetPassForm.email': 'E-posta Adresi',
        'resetPassForm.submit': 'Gönder',

        'resetPassPage.title': 'Şifre Sıfırla',
        'resetPassPage.successAlert':
          'Şifre sıfırlama talimatları eposta adresinize gönderildi.',
        'resetPassPage.login': 'Bunun yerine giriş yapın',

        'studentAddPage.title': 'Yeni Talebe',
        'studentEditPage.title': 'Talebe Düzenle',

        'studentDetailPage.title': 'Talebe Detayları',
        'studentDetailPage.deleteStudent': 'Talebe Silinecek',
        'studentDetailPage.deleteStudentContent': 'Bu işlem geri alınamaz.',
        'studentDetailPage.deleteStudentTitle': 'Sil',
        'studentDetailPage.firstName': 'İsmi',
        'studentDetailPage.lastName': 'Soyismi',
        'studentDetailPage.age': '{{age}} Yaş',
        'studentDetailPage.place': 'Mahal',
        'studentDetailPage.school': 'Okulu',
        'studentDetailPage.guardianName': 'Veli İsmi',
        'studentDetailPage.guardianPhoneNumber': 'Veli İletişim',
        'studentDetailPage.phoneNumber': 'İletişim',
        'studentDetailPage.studentType': 'Talebe Türü',
        'studentDetailPage.return': 'Geri Dön',
        'studentDetailPage.edit': 'Düzenle',
        'studentDetailPage.printCert': "Kur'an-ı Kerime Geçme Belgesi",

        'studentPrintCertPage.title': "Kur'an-ı Kerime Geçme Belgesi",
        'studentPrintCertPage.description': 'Lütfen bir tasarım seçiniz.',
        'studentPrintCertPage.design': 'Şablon {{number}}',

        'studentGender.male': 'Erkek',
        'studentGender.female': 'Kız',

        'studentYearInSchool.preSchool': 'Okul Öncesi',
        'studentYearInSchool.year1': '1. Sınıf',
        'studentYearInSchool.year2': '2. Sınıf',
        'studentYearInSchool.year3': '3. Sınıf',
        'studentYearInSchool.year4': '4. Sınıf',
        'studentYearInSchool.year5': '5. Sınıf',
        'studentYearInSchool.year6': '6. Sınıf',
        'studentYearInSchool.year7': '7. Sınıf',
        'studentYearInSchool.year8': '8. Sınıf',
        'studentYearInSchool.year9': '9. Sınıf',
        'studentYearInSchool.year10': '10. Sınıf',
        'studentYearInSchool.year11': '11. Sınıf',
        'studentYearInSchool.year12': '12. Sınıf',

        'studentForm.age': 'Yaşı',
        'studentForm.firstName': 'İsmi',
        'studentForm.guardianName': 'Veli İsim Soyisim',
        'studentForm.guardianPhoneNumber': 'Veli İletişim',
        'studentForm.phoneNumber': 'İletişim',
        'studentForm.lastName': 'Soyismi',
        'studentForm.school': 'Okul İsmi',
        'studentForm.studentType': 'Talebe Türü',
        'studentForm.studentPlace': 'Talebe Mahalli',
        'studentForm.gender': 'Cinsiyeti',
        'studentForm.yearInSchool': 'Sınıfı',
        'studentForm.yearInSchoolHelperText': 'Sadece talebeler için giriniz',
        'studentForm.yearInSchoolEmptyOption': 'Seçim yok',

        'studentsPage.deletePlace': 'Talebe Silinecek',
        'studentsPage.deletePlaceContent': 'Bu işlem geri alınamaz.',
        'studentsPage.title': 'Talebeler',
        'studentsPage.noRecords': 'Henüz kayıt oluşturulmadı',

        'teacherAddPage.title': 'Yeni Okutucu',
        'teacherEditPage.title': 'Okutucu Düzenle',

        'teacherDetailPage.title': 'Okutucu Detayları',
        'teacherDetailPage.deleteTeacher': 'Okutucu Silinecek',
        'teacherDetailPage.deleteTeacherContent': 'Bu işlem geri alınamaz.',
        'teacherDetailPage.deleteTeacherTitle': 'Sil',
        'teacherDetailPage.firstName': 'İsmi',
        'teacherDetailPage.lastName': 'Soyismi',
        'teacherDetailPage.phoneNumber': 'İletişim',
        'teacherDetailPage.username': 'Kullanıcı Adı',
        'teacherDetailPage.pin': 'Şifre',
        'teacherDetailPage.teacherType': 'Okutucu Türü',
        'teacherDetailPage.place': 'Mahal',
        'teacherDetailPage.active': 'Aktiflik Durumu',
        'teacherDetailPage.disableTeacher': 'Okutucuyu Pasif Yap',
        'teacherDetailPage.enableTeacher': 'Okutucuyu Aktif Yap',
        'teacherDetailPage.disableTeacherContent':
          'Okutucu pasifleştirme işlemini yapmak üzeresiniz.',
        'teacherDetailPage.enableTeacherContent':
          'Okutucu aktifleştirme işlemini yapmak üzeresiniz.',
        'teacherDetailPage.return': 'Geri Dön',
        'teacherDetailPage.edit': 'Düzenle',

        'teacherForm.firstName': 'İsmi',
        'teacherForm.lastName': 'Soyismi',
        'teacherForm.phoneNumber': 'İletişim',
        'teacherForm.pin': 'Şifre',
        'teacherForm.teacherType': 'Okutucu Türü',

        'teachersPage.deletePlace': 'Okutucu Silinecek',
        'teachersPage.deletePlaceContent': 'Bu işlem geri alınamaz.',
        'teachersPage.title': 'Okutucular',
        'teachersPage.noRecords': 'Henüz kayıt oluşturulmadı',
      },
    },
  },
  lng: 'tr',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
