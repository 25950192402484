import {
  Alert,
  Box,
  Button,
  Grid,
  Grow,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { APIException } from '../common/exception';
import { toTitleCase } from '../common/utils';
import { useConfirm } from '../hooks/useConfirm';
import { AuthContainer } from '../stores/Auth';
import { TeacherContainer } from '../stores/Teacher';
import { TeachersContainer } from '../stores/Teachers';

export const TeacherDetailPage = () => {
  let id: number;
  const { id: idq } = useParams();
  if (idq) id = parseInt(idq, 10);
  const nav = useNavigate();
  const [error, setError] = useState<APIException>();
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();
  const { Dialog, ask } = useConfirm();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const {
    teacher,
    teacherLoading,
    enableTeacher,
    disableTeacher,
    fetchTeacher,
  } = TeacherContainer.useContainer();

  const { deleteTeacher } = TeachersContainer.useContainer();

  const handleDelete = async (id: number) => {
    try {
      const res = await ask(
        t('teacherDetailPage.deleteTeacher'),
        t('teacherDetailPage.deleteTeacherContent'),
      );

      if (res) {
        deleteTeacher(id);
        nav('/teachers');
      }
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  const handleEnable = async (id: number) => {
    try {
      const res = await ask(
        t('teacherDetailPage.enableTeacher'),
        t('teacherDetailPage.enableTeacherContent'),
      );
      if (res) {
        enableTeacher(id);
        fetchTeacher(id);
      }
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  const handleDisable = async (id: number) => {
    try {
      const res = await ask(
        t('teacherDetailPage.disableTeacher'),
        t('teacherDetailPage.disableTeacherContent'),
      );
      if (res) {
        disableTeacher(id);
        fetchTeacher(id);
      }
    } catch (error) {
      if (error instanceof APIException) {
        setError(error);
        console.error(error.message);
      }
    }
  };

  return (
    <>
      <Dialog />
      <Box mt={1}>
        {error && error?.state?.general && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error.state.general}
          </Alert>
        )}
        <Grow
          in={!teacherLoading}
          style={{ transformOrigin: '0 0 0' }}
          timeout="auto"
        >
          <Paper
            elevation={2}
            sx={{
              mt: 2,
            }}
          >
            {!error && teacherLoading && (
              <Box sx={{ pb: 2 }}>
                <LinearProgress />
              </Box>
            )}
            {teacher && (
              <Box sx={{ p: 2 }}>
                {/* <Grid container spacing={0.5}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" align="right" sx={{mt: 0.5, mr: 0.5}}>
                    {t('teacherDetailPage.firstName')}:{' '}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    {`${toTitleCase(teacher.first_name)}`}
                  </Typography>
                </Grid>
              </Grid> 
              <Grid container spacing={0.5}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" align="right" sx={{mt: 0.5, mr: 0.5}}>
                    {t('teacherDetailPage.lastName')}:{' '}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    {`${toTitleCase(teacher.last_name)}`}
                  </Typography>
                </Grid>
              </Grid>  */}
                <Typography variant="h6" align="center">
                  {`${toTitleCase(teacher.first_name)}`}{' '}
                  {`${toTitleCase(teacher.last_name)}`}
                </Typography>
                {teacher.phone_number && (
                  <Grid container spacing={0.5} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        color="textSecondary"
                        sx={{ mt: 0.5, mr: 0.5 }}
                      >
                        {t('teacherDetailPage.phoneNumber')}:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {`${toTitleCase(teacher.phone_number)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={0.5}>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      color="textSecondary"
                      sx={{ mt: 0.5, mr: 0.5 }}
                    >
                      {t('teacherDetailPage.teacherType')}:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {`${toTitleCase(teacher.teacher_user_type.name)}`}
                    </Typography>
                  </Grid>
                </Grid>
                {teacher.places.map((place) => (
                  <Typography variant="subtitle1" key={place.id} align="center">
                    {`${toTitleCase(place.name)}`}
                  </Typography>
                ))}
                {/* <Grid container spacing={0.5}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" align="right" sx={{mt: 0.5, mr: 0.5}}>
                    {t('teacherDetailPage.place')}:{' '}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {teacher.places.map((place) => (
                  <Typography variant="h6" key={place.id} align="center">
                    {`${toTitleCase(place.name)}`}
                  </Typography>
                  ))}
                </Grid>
              </Grid>  */}
              </Box>
            )}
          </Paper>
        </Grow>
        <Grow
          in={!teacherLoading}
          style={{ transformOrigin: '0 0 0' }}
          timeout="auto"
        >
          <Paper sx={{ mt: 2 }}>
            {teacherLoading && (
              <Box sx={{ pb: 2 }}>
                <LinearProgress />
              </Box>
            )}
            {teacher?.access_code && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  align="center"
                >
                  {t('teacherDetailPage.username')}
                </Typography>
                <Typography variant="subtitle1" component="div" align="center">
                  {`${toTitleCase(teacher.username)}`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  align="center"
                >
                  {t('teacherDetailPage.pin')}
                </Typography>
                <Typography variant="subtitle1" component="div" align="center">
                  {teacher.access_code.code}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grow>
        <Grow
          in={!teacherLoading}
          style={{ transformOrigin: '0 0 0' }}
          timeout="auto"
        >
          <Paper sx={{ mt: 2 }}>
            {teacherLoading && (
              <Box sx={{ pb: 2 }}>
                <LinearProgress />
              </Box>
            )}
            {teacher?.access_code && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                {teacher.is_active ? (
                  <>
                    <Typography variant="subtitle1" component="div">
                      Aktif
                    </Typography>
                    <Button
                      size="small"
                      //sx={{ml: 6, alignItems:"center"}}
                      //startIcon={<BlurOffIcon />}
                      onClick={() => handleDisable(id)}
                    >
                      Pasifleştir
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle1" component="div">
                      Pasif
                    </Typography>
                    <Button
                      size="small"
                      //sx={{ml: 6}}
                      //startIcon={<BlurOnIcon />}
                      onClick={() => handleEnable(id)}
                    >
                      Aktifleştir
                    </Button>
                  </>
                )}
              </Box>
            )}
          </Paper>
        </Grow>
        <Paper sx={{ mt: 1, p: 1 }}>
          <Stack direction={mdUp ? 'row' : 'column'} spacing={1} sx={{ mt: 3 }}>
            <Button color="secondary" type="button" onClick={() => nav(-1)}>
              {t('teacherDetailPage.return')}
            </Button>
            {can('update_teacheruser') && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => nav(`/teachers/${id}/edit`)}
              >
                {t('teacherDetailPage.edit')}
              </Button>
            )}
            {can('delete_teacheruser') && (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(id)}
              >
                {t('teacherDetailPage.deleteTeacherTitle')}
              </Button>
            )}
          </Stack>
        </Paper>
      </Box>
    </>
  );
};
