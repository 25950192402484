import {
  Box,
  ButtonBase,
  CircularProgress,
  Hidden,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { printCert } from '../api';

declare let GA_ENABLED: boolean;
declare let gtag: (
  ob: 'event',
  action: string,
  meta?: { [key: string]: string },
) => void;

export const StudentPrintCertPage = () => {
  const [downloading, setDownloading] = useState(0);
  const { t } = useTranslation();
  const { id } = useParams();

  if (!id) return null;

  const download = async (design: number, order: number) => {
    setDownloading(design);
    const res = await printCert(parseInt(id), design);
    const blob = await res.blob();
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.download =
      res.headers.get('Content-Disposition')?.split(';')[1]?.split('=')[1] ||
      '';
    a.click();
    setDownloading(0);

    if (GA_ENABLED) {
      gtag('event', 'print_cert', {
        design: order.toString(),
        link_text: t('studentPrintCertPage.design', { number: order }),
      });
    }
  };

  return (
    <Box mt={1}>
      <Hidden mdDown>
        <Typography variant="h5">{t('studentPrintCertPage.title')}</Typography>
      </Hidden>
      <Paper
        elevation={2}
        sx={{
          mt: 2,
          p: 2,
        }}
      >
        <Typography mb={2}>{t('studentPrintCertPage.description')}</Typography>
        <Box
          sx={(t) => ({
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            [t.breakpoints.down('md')]: {
              justifyContent: 'center',
            },
          })}
        >
          {[5, 1, 3, 2, 4].map((d, i) => (
            <ImageButton
              focusRipple
              key={`design${d}`}
              style={{
                width: 240,
              }}
              onClick={() => download(d, i + 1)}
            >
              <ImageSrc
                style={{ backgroundImage: `url(/cert-thumbs/${d}.jpeg` }}
              />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className="MuiImageButtonTypography-root"
                  sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  {downloading === d ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    t('studentPrintCertPage.design', { number: i + 1 })
                  )}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            </ImageButton>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 170,
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageButtonTypography-root': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));
