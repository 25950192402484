import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiStudent, ApiStudentInput } from '../api-types';

function useStudents() {
  const [students, setStudents] = useState<ApiStudent[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(window.DEFAULT_PAGE_SIZE);
  const [studentsLoading, setStudentsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  const fetchStudents = async () => {
    if (studentsLoading) {
      return;
    }
    setStudentsLoading(true);
    const data = await api.students({ page, size });
    setTotal(data.count);
    setStudents(data.results);
    setStudentsLoading(false);
  };

  const addStudent = async (data: ApiStudentInput) => {
    setSubmitting(true);
    const added = await api.addStudent(data);
    setStudents([...students, added]);
    setSubmitting(false);
  };

  const deleteStudent = async (id: number) => {
    setSubmitting(true);
    await api.deleteStudent(id);
    setStudents(students.filter((p) => p.id !== id));
    setSubmitting(false);
  };

  return {
    students,
    studentsLoading,
    studentsTotal: total,
    setStudents,
    fetchStudents,
    addStudent,
    deleteStudent,
    setStudentsPage: setPage,
    setStudentsSize: setSize,
    submitting,
  };
}

export const StudentsContainer = createContainer(useStudents);
