import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiCity, ApiDistrict } from '../api-types';
import * as api from '../api';

type DistrictACProps = {
  city: ApiCity;
  value?: ApiDistrict | null;
  onChange: (value: ApiDistrict | null) => void;
  allowCreate?: boolean;
};

export const DistrictAC = ({
  city,
  value,
  onChange,
  allowCreate,
}: DistrictACProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<ApiDistrict[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, searchTerm]);

  const fetchOptions = async () => {
    setLoading(true);
    const data = await api.districts({
      city: String(city.id),
      search: searchTerm,
    });
    let options = data.results;
    if (allowCreate && searchTerm && options?.length === 0) {
      options = [
        { title: t('autocomplete.addNew', { searchTerm }), id: -1, city },
      ];
    }
    setOptions(options);
    setLoading(false);
  };

  const handleChange = async (v: ApiDistrict | string | null) => {
    if (!v || typeof v === 'string') return;
    if (v.id === -1) {
      setLoading(true);
      const data = await api.addDistrict({
        title: searchTerm,
        city: city.id,
      });
      setOptions([...options, data]);
      onChange(data);
      setLoading(false);
    } else {
      onChange(v);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={(e, v) => handleChange(v)}
      freeSolo
      disablePortal
      selectOnFocus
      clearOnBlur
      id="district-ac"
      options={options}
      sx={{ width: ['100%', '100%', '100%'] }}
      loading={loading}
      getOptionLabel={(opt) => opt.title}
      renderOption={(props, opt) => (
        <MenuItem {...props} value={opt.id}>
          {opt.title}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('districtAc.label')}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
    />
  );
};
