import { AddTwoTone as AddIcon, SearchTwoTone } from '@mui/icons-material';
import {
  Box,
  Chip,
  Fab,
  LinearProgress,
  List,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toTitleCase } from '../common/utils';
import { ListItem } from '../components/ListItem';
import { AuthContainer } from '../stores/Auth';
import { TeachersContainer } from '../stores/Teachers';

export const TeachersPage = () => {
  const { t } = useTranslation();
  const { can } = AuthContainer.useContainer();

  const { page, size, setPage, setSize, teachers, total, loading } =
    TeachersContainer.useContainer();

  return (
    <Box mt={1}>
      <Paper
        elevation={2}
        sx={{
          mb: [8, 8, 'unset'],
          position: [null, null, 'relative'],
          mt: [0, 2, 4],
        }}
      >
        {loading && <LinearProgress />}
        {can('add_teacheruser') && (
          <Fab
            component={Link}
            to="/teachers/new"
            color="secondary"
            sx={{
              position: ['fixed', 'fixed', 'absolute'],
              top: [null, null, -28],
              bottom: [70, null, null],
              right: 28,
            }}
          >
            <AddIcon />
          </Fab>
        )}
        {/* <Autocomplete
            sx={{ my: 2 }}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={teachers.map((s) => s.first_name + ' ' + s.last_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Arama"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          /> */}
        <List>
          {!loading && teachers?.length === 0 && (
            <ListItem
              icon={<SearchTwoTone />}
              primaryText={
                <Typography>{t('teachersPage.noRecords')}</Typography>
              }
            />
          )}
          {teachers.map((teacher) => (
            <ListItem
              key={teacher.id}
              primaryText={
                <>
                  {`${toTitleCase(
                    teacher.first_name + ' ' + teacher.last_name,
                  )}`}{' '}
                  <Chip
                    component="span"
                    color="secondary"
                    variant="outlined"
                    label={teacher.teacher_user_type.name}
                    size="small"
                  />
                  {teacher.access_code && (
                    <Chip
                      component="span"
                      color={teacher.is_active ? 'success' : 'warning'}
                      variant="filled"
                      label={teacher.is_active ? 'Aktif' : 'Pasif'}
                      size="small"
                      sx={{
                        position: 'absolute',
                        right: '3%',
                      }}
                    />
                  )}
                </>
              }
              linkTo={`/teachers/${teacher.id}/detail`}
            />
          ))}
        </List>
        <TablePagination
          component="div"
          count={total}
          page={page - 1}
          onPageChange={(e, v) => setPage(v + 1)}
          rowsPerPage={size}
          rowsPerPageOptions={window.PAGINATION_OPTS}
          onRowsPerPageChange={(e) => setSize(parseInt(e.target.value))}
          labelRowsPerPage={t('pagination.sizeLabel')}
          labelDisplayedRows={({ from, to, count }) =>
            t('pagination.displayLabel', { from, to, count })
          }
        />
      </Paper>
    </Box>
  );
};
