import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import * as api from '../api';
import type { ApiStudent, ApiStudentInput } from '../api-types';
import { StudentsContainer } from './Students';

function useStudent(initialState?: { id: number }) {
  const { students, setStudents } = StudentsContainer.useContainer();
  const [student, setStudent] = useState<ApiStudent | null>(null);
  const [studentLoading, setStudentLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (initialState?.id) fetchStudent(initialState?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.id]);

  const fetchStudent = async (id: number) => {
    if (studentLoading) {
      return;
    }
    setStudentLoading(true);
    const data = await api.student(id);
    setStudent(data);
    setStudentLoading(false);
  };

  const updateStudent = async (id: number, data: ApiStudentInput) => {
    setSubmitting(true);
    const updated = await api.updateStudent(id, data);
    const newStudents = students.map((s) => (s.id === id ? updated : s));
    if (student?.id === updated.id) setStudent(updated);
    setStudents(newStudents);
    setSubmitting(false);
  };

  return {
    student,
    studentLoading,
    fetchStudent,
    updateStudent,
    submitting,
  };
}

export const StudentContainer = createContainer(useStudent);
